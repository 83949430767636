import React from 'react';

import MediaQuery from 'react-responsive';

import { Button, Col, Form, Icon, Row, Select, Spin, Typography } from 'antd';

const { Option } = Select;
const { Text } = Typography;

const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class Essentials extends React.Component {

  state = {
    core_relation: this.props.core_relation,
  }

  handleFormSubmit = (event) => {
    event.preventDefault();

    let updateContent = {
      category:'default',
      title:'default',
      description:'default',
      thumbnail:'default',
      video:'default',
      core_relation: this.state.core_relation,
    }
    this.props.updateContent(updateContent);
  };

  render() {

    const core_relation = [];

    this.props.core.forEach((core) => {
      core_relation.push(<Option key={core.id}>{core.title}</Option>);
    });

    let temp = this.state.core_relation;
    return(
      <React.Fragment>
      {
        this.props.sending?
        <div className="loader">
          <Spin tip="¡Gran actualización! Espera mientras validamos tu contenido en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
        </div>
       :
        <Form onSubmit={(event) => this.handleFormSubmit(event)}>

        <Text strong>¿Te gustaría vincular tu curso con cualquier curso teórico?</Text><br />
        <Form.Item style={{marginTop: 20}}>
          <Select
            mode="multiple"
            showSearch
            placeholder="selecciona los cursos teóricos vinculados para tu contenido"
            optionFilterProp="children"
            onChange={(value) => {

              Object.entries(value).forEach(
                  ([key, val]) => {
                    console.log(key, parseInt(val, 10));
                    if(!this.state.core_relation.includes(parseInt(val, 10))){
                      temp.push(parseInt(val, 10));
                      console.log('updated temp');
                      console.log(temp);
                      this.setState({core_relation : temp});
                    }
                  }
              );


            }}
            onSearch={(value) => {

              if(!this.state.core_relation.includes(parseInt(value, 10))){
                temp.push(parseInt(value, 10));
                this.setState({core_relation : temp});
              }
            }}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {core_relation}

          </Select>
        </Form.Item>


        <Form.Item>
        <Row>
        <MediaQuery minDeviceWidth={768}>
          {(matches) =>
          matches
            ? <Row>
              <Col span={5}>
                <Button type="primary" htmlType="submit" >Actualización del curso</Button>
              </Col>
              <Col span={4} offset={1}>
                <Button type="default" href={`/example/${this.props.init_content.slug}`} >Ver curso</Button>
              </Col>
            </Row>
            : <div>
              <Row>
                <Col>
                  <Button type="primary" htmlType="submit" block>Actualización del curso</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="default" href={`/example/${this.props.init_content.slug}`} block>Ver curso</Button>
                </Col>
              </Row>
            </div>
          }
        </MediaQuery>
        </Row>

        </Form.Item>
        </Form>
      }
      </React.Fragment>
    );
  }
}

export default Essentials;
