import React from 'react';

import MediaQuery from 'react-responsive';

import { beforeUpload, getBase64, loadXHR } from '../../../../file-processor-api';

import { Button, Col, Form, Icon, Row, Spin, Typography, Upload } from 'antd';

const { Text } = Typography;

const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class Infographics extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        thumbnailUrl: this.props.init_content.thumbnail,
        infographicsUrl: this.props.init_content.infographics,
    };
  }

  componentDidMount() {
    !this.state.thumbnailUrl ? this.setState({thumbnailUrl: ''})
                          : loadXHR(this.state.thumbnailUrl)
                          .then((blob) => {
                            return new Promise((resolve, reject) => {
                              const reader = new FileReader();
                              reader.readAsDataURL(blob);
                              reader.onload = () => resolve(reader.result);
                              reader.onerror = error => reject(error);

                            })
                            .then(data => {
                              this.setState({
                                thumbnailUrl: data
                              });
                            });
                          });

    !this.state.infographicsUrl ? this.setState({infographicsUrl: ''})
                          : loadXHR(this.state.infographicsUrl)
                          .then((blob) => {
                            return new Promise((resolve, reject) => {
                              const reader = new FileReader();
                              reader.readAsDataURL(blob);
                              reader.onload = () => resolve(reader.result);
                              reader.onerror = error => reject(error);

                            })
                            .then(data => {
                              this.setState({
                                infographicsUrl: data
                              });
                            });
                          });
  }

  handleFormSubmit = event => {
    event.preventDefault();

    let updateContent = {
      category:'default',
      title:'default',
      description:'default',
      thumbnail:this.state.thumbnailUrl,
      infographics:this.state.infographicsUrl,
      video:'default',
      audio:'default',
      textArticle: 'default',
      previous: 'default',
      next:'default',

    }
    this.props.updateContent(updateContent);
  }

  handleThumbnailChange = info => {
     if (info.file.status === 'uploading') {
       this.setState({ loading: true });
       return;
     }
     if (info.file.status === 'done') {
       // Get this url from response in real world.
       getBase64(info.file.originFileObj, thumbnailUrl => {
         this.setState({
           thumbnailUrl,
           loading: false,
         });
       });
     }
   };

  handleInfographicsChange = info => {
      if (info.file.status === 'uploading') {
        this.setState({ loading: true });
        return;
      }
      if (info.file.status === 'done') {
        // Get this url from response in real world.
        getBase64(info.file.originFileObj, infographicsUrl => {
          this.setState({
            infographicsUrl,
            loading: false,
          });
        });
      }
    };

  render() {

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Subir</div>
      </div>
    );
    const { thumbnailUrl, infographicsUrl } = this.state;

    return(
      <React.Fragment>
      {
        this.props.sending?
        <div className="loader">
          <Spin tip="¡Gran actualización! Espera mientras validamos tu contenido en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
        </div>
       :
        <Form onSubmit={ (event) => this.handleFormSubmit(event)}>

          <Text><strong>Haz clic a continuación para actualizar la imagen de portada de tu curso. </strong></Text>

          <Form.Item style={{marginTop: 20, padding: 10}}>
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={this.handleThumbnailChange}
          >
            {thumbnailUrl ? <img src={thumbnailUrl} alt="thumbnail-file" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
          </Form.Item>

          <Text><strong>Haz clic a continuación para actualizar las infografías de tu curso.</strong> </Text>

          <Form.Item style={{marginTop: 20, padding: 10}}>
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={this.handleInfographicsChange}
          >
            {infographicsUrl ? <img src={infographicsUrl} alt="infographics-file" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
          </Form.Item>
          <Text>Tenga en cuenta que el tipo admitido incluye <i> jpeg </i> y <i> png </i>. Límite máximo de carga <i> 2MB</i></Text>
          <br />
          <Form.Item>
          <MediaQuery minDeviceWidth={768}>
            {(matches) =>
            matches
              ? <Row>
                <Col span={5}>
                  <Button type="primary" htmlType="submit" >Actualización del curso</Button>
                </Col>
                <Col span={4} offset={1}>
                  <Button type="default" href={`/core/${this.props.init_content.slug}`} >Ver curso</Button>
                </Col>
              </Row>
              : <div>
                <Row>
                  <Col>
                    <Button type="primary" htmlType="submit" block>Actualización del curso</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type="default" href={`/core/${this.props.init_content.slug}`} block>Ver curso</Button>
                  </Col>
                </Row>
              </div>
            }
          </MediaQuery>
          </Form.Item>

        </Form>
      }
      </React.Fragment>
    );
  }
}

export default Infographics;
