import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 k8nwszmas99-editor_css' },
  page: { className: 'home-page banner5-page k8nwu268o7e-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Trabaja en Panzofi</p>
          </span>
        ),
        className: 'banner5-title k8nwvuu3b4l-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain k8nwo1mbjcq-editor_css',
        children: (
          <span>
            <p>
              Siempre estamos buscando personas apasionadas por la educación
              para unirse al equipo
            </p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://forms.gle/Sudyy76cW25gQ4CF7',
          className: 'banner5-button k8nwxnkg9k9-editor_css',
          type: 'primary',
          children: (
            <span>
              <p>Envía tu CV</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: 'https://s3.amazonaws.com/panzofi.com/Banner-trabajar.svg',
  },
};
export const Teams10DataSource = {
  wrapper: {
    className: 'home-page-wrapper teams1-wrapper k8nwtd8pqs-editor_css',
  },
  page: { className: 'home-page teams1' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Únete a nuestro equipo</p>
          </span>
        ),
        className: 'k8nww18nab-editor_css',
      },
    ],
  },
  block: {
    className: 'block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: 'https://s3.amazonaws.com/panzofi.com/Trabajo-1.svg',
            },
            {
              name: 'title',
              className: 'teams1-title k8nww6htkt9-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Nuestra cultura</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content k8nvjplvkkp-editor_css',
              children: (
                <span>
                  <span>
                    <p>
                      Tenemos un enfoque a largo plazo y reconocemos la
                      importancia de las personas. Estas son las que impulsan la
                      innovación y crean un entorno de trabajo colaborativo
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: 'https://s3.amazonaws.com/panzofi.com/Trabajo-2.svg',
            },
            {
              name: 'title',
              className: 'teams1-title k8nww9i5y06-editor_css',
              children: (
                <span>
                  <p>Nuestros valores</p>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content k8nvnshd2n-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Personas apasionadas, y enfocadas en resultados.
                        Personas que solucionen problemas buscando el mejor
                        camino. Líderes en constante aprendizaje para entregar
                        un servicio óptimo a los clientes
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 8,
        xs: 24,
        titleWrapper: {
          children: [
            {
              name: 'image',
              className: 'teams1-image',
              children: 'https://s3.amazonaws.com/panzofi.com/Trabajo-3.svg',
            },
            {
              name: 'title',
              className: 'teams1-title k8nwwco6j1-editor_css',
              children: (
                <span>
                  <span>
                    <p>Nuestro equipo</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content1',
              className: 'teams1-content k8nvwd0js1n-editor_css',
              children: (
                <span>
                  <span>
                    <p>
                      Un equipo diverso y de alto rendimiento.&nbsp;El éxito de
                      la compañía se debe a sus empleados y compartiremos ese
                      éxito con aquellos que asumen la responsabilidad y se
                      desempeñan bien
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature7-wrapper k8nwtoakyqr-editor_css',
  },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1 k8nwwgnxsr-editor_css',
        children: (
          <span>
            <p>Ofertas de trabajo actuales</p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8nwqb2e5gt-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8nvyxuncd7-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nwwjscayk-editor_css',
              children: (
                <span>
                  <span>
                    <p>Arquitecto de Software - Cloud</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nwol2qgdh-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Bogotá, Colombia</p>
                        <p>Clic para más información</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
          href:
            'https://drive.google.com/file/d/1RLrGI9C-PWZcN6czbB2v09hgLh8mGpbw/view?usp=sharing',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8nwpv5vrhq-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8nwfc72ygn-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nwwn94fb6-editor_css',
              children: (
                <span>
                  <p>Backend Developer</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nwp09qaqc-editor_css',
              children: (
                <span>
                  <p>Bogotá, Colombia</p>
                  <p>Clic para más información</p>
                </span>
              ),
            },
          ],
          href:
            'https://drive.google.com/file/d/1PW8yckL23gB3YtJjjz4aVdjainLKxtIX/view?usp=sharing',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8nwpci78cl-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8nwfedbscq-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nwwrgxpkf-editor_css',
              children: (
                <span>
                  <p>Frontend Developer</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nwpel22xd-editor_css',
              children: (
                <span>
                  <p>Bogotá, Colombia</p>
                  <p>Clic para más información</p>
                </span>
              ),
            },
          ],
          href:
            'https://drive.google.com/file/d/1_oPfWLxszf6VhSsFiQVMIXfExovWKCH_/view?usp=sharing',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8nwqqadvfs-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8nwfg0n7gb-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nwwza3wj-editor_css',
              children: (
                <span>
                  <p>UI / UX</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nwqlqpb6h-editor_css',
              children: (
                <span>
                  <p>Bogotá, Colombia</p>
                  <p>Clic para más información</p>
                </span>
              ),
            },
          ],
          href:
            'https://drive.google.com/file/d/1GGoSOUkwuB4AmolZLHGYm9nLy9Rw6e7d/view?usp=sharing',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8nwqy1wsm-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8nwfhp5r2j-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nwx5qcu4n-editor_css',
              children: (
                <span>
                  <span>
                    <p>Practicante - Developer</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nwr4geubm-editor_css',
              children: (
                <span>
                  <p>Bogotá, Colombia</p>
                  <p>Clic para más información</p>
                </span>
              ),
            },
          ],
          href:
            'https://drive.google.com/file/d/1lJJ5hyudoBLBU9FC3rOQ27SEu6wl94Ym/view?usp=sharing',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8nwrcg78x-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8nwfjj7ei-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nwx2l2qwu-editor_css',
              children: (
                <span>
                  <p>Practicante - Operaciones</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nwr7ywbxo-editor_css',
              children: (
                <span>
                  <p>Bogotá, Colombia</p>
                  <p>Clic para más información</p>
                </span>
              ),
            },
          ],
          href:
            'https://drive.google.com/file/d/1VJRIynATh0QGRyqtRBd9W-Q0HUbD2U9d/view?usp=sharing',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8nwezw4iw-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '身份证',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8nwf4ueo56-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: '身份证',
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: '识别身份证正反面姓名、身份证号、发证机关等相关信息',
            },
          ],
        },
      },
    ],
  },
};
