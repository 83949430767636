import React from 'react';

import { Button, Card, Col, Divider, Icon, Row, Tooltip, Typography} from 'antd';

import ReactPlayer from 'react-player';


const { Paragraph } = Typography;

class ExampleDetail extends React.Component{

  render() {
    
    return(
      <div>
        {
          this.props.loggedUser === this.props.example.author ?
          <div>
            <Row type="flex" justify="start">

              <Col span={18} offset={1}>
                <Col span={24}>
                  <Button href='/example/' type="primary"> Explora otros cursos teóricos </Button>
                </Col>
              </Col>

              <Col span={5} justify="start">
                <Col span={10}>
                  <Button href={`/example/${this.props.slug}/update/`}> Actualizar </Button>
                </Col>
                <Col span={10}>
                  <Button onClick={this.props.showDeleteConfirm} type="danger"> Eliminar </Button>
                </Col>
              </Col>

            </Row>
            <Divider />
          </div>
          :
          <div>
            <Row type="flex" justify="start">
              <Col span={3} offset={1}>
                <Button href='/example/'  type="primary"> Explora otros cursos prácticos </Button>
              </Col>
            </Row>
            <Divider />
          </div>
        }

        <Row>
          <Col>
            <Card
              hoverable={true}
              bordered={true}
              actions={
                [

                  <Tooltip title="Divertido">
                    <span>
                       <Icon style={{ marginRight: 8, fontSize: '25px' }} type="smile" theme="twoTone" twoToneColor="#f67177" onClick={this.props.funHandler} key="list-vertical-smile"/>{this.props.fun}
                    </span> </Tooltip>,
                  <Tooltip title="No divertido">
                    <span>
                       <Icon style={{ marginRight: 8, fontSize: '25px' }} type="frown" theme="twoTone" twoToneColor="#f67177" onClick={this.props.nofunHandler} key="list-vertical-frown"/>{this.props.no_fun}
                    </span> </Tooltip>,
                  <Tooltip title="Eficiente">
                   <span>
                       <Icon style={{ marginRight: 8, fontSize: '25px' }} type="star" theme="twoTone" twoToneColor="#f67177" onClick={this.props.efficientHandler} key="list-vertical-star" />{this.props.efficient}
                   </span> </Tooltip>,
                  <Tooltip title="Útil">
                   <span>
                       <Icon style={{ marginRight: 8, fontSize: '25px' }} type="bulb" theme="twoTone" twoToneColor="#f67177" onClick={this.props.usefulHandler} key="list-vertical-bulb" />{this.props.useful}
                   </span> </Tooltip>,

                  <Tooltip title="Vistas totales">
                    <span>
                       <Icon style={{ marginRight: 8, fontSize: '25px' }} type="eye" theme="twoTone" twoToneColor="#f67177" key="list-vertical-eye" />{this.props.example.total_views}
                    </span>
                  </Tooltip>

                ]}
            >
              <h1 style={{ marginTop: 10 }}>{this.props.example.title}</h1>
              <small> Autor: { this.props.example.author } | Publicado en: { this.props.example.publish } </small>
              <Divider />

              <ReactPlayer
                url={this.props.example.video}
                playing={false}
                controls={true}
                height='200'
                width='300'
              />


            </Card>
            <Card
              hoverable={true}
              bordered={true}
            >
              <h4>Descripción del curso:</h4>
              <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                {this.props.example.description}
              </Paragraph>
            </Card>
          </Col>


        </Row>

      </div>
    );
  };

}

export default ExampleDetail;
