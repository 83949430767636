import React from 'react';

import { Card, Col, Divider, Icon, List, Progress, Row, Statistic, Typography } from 'antd';

const { Paragraph, Title, Text } = Typography;
const { Meta } = Card;

class Profile extends React.Component {

  state = {
    avatar: "https://s3.amazonaws.com/panzofi.com/Avatar.png"
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.profile.profile_picture !== this.props.profile.profile_picture){
      this.componentDidMount()
    }
  }

  componentDidMount() {
    if(this.props.profile.profile_picture) {
      this.setState({
        avatar: this.props.profile.profile_picture
      });
    }
  }

  render() {
    return(
      <Row>
        <Col span={16}>
          <Title level={4}> Contenido teórico publicado </Title>
          <List
            style={{padding: 20}}
            itemLayout="vertical"
            size="default"
            pagination={{
              onChange: page => {
                console.log(page);
              },
              pageSize: 4,
              hideOnSinglePage: true
            }}
            dataSource={this.props.profile.core_content_posted}
            renderItem={item => (
              <List.Item
                key={item.title}
                actions={[
                  <span><Icon
                    style={{ marginRight: 8 }}
                     type="calendar" theme="twoTone" twoToneColor="#f67177" key="list-vertical-calendar" /> Publicado en: {item.publish}
                  </span>,

                  <span><Icon
                    style={{ marginRight: 8 }}
                    type="eye" theme="twoTone" twoToneColor="#f67177" key="list-vertical-eye" />Vistas totales: {item.total_views}
                  </span>,

                ]}
              >
                <List.Item.Meta
                  title={<a href={`/core/${item.slug}`}>{item.title}</a>}
                  description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
                />

              </List.Item>
            )}
          />
          <Title level={4}> Contenido práctico publicado </Title>
          <List
            style={{padding: 20}}
            itemLayout="vertical"
            size="default"
            pagination={{
              onChange: page => {
                console.log(page);
              },
              pageSize: 4,
              hideOnSinglePage: true
            }}
            dataSource={this.props.profile.example_content_posted}
            renderItem={item => (
              <List.Item
                key={item.title}
                actions={[
                  <span><Icon
                    style={{ marginRight: 8 }}
                     type="calendar" theme="twoTone" twoToneColor="#f67177" key="list-vertical-calendar" /> Publicado en: {item.publish}
                  </span>,

                  <span><Icon
                    style={{ marginRight: 8 }}
                    type="eye" theme="twoTone" twoToneColor="#f67177" key="list-vertical-eye" />Vistas totales: {item.total_views}
                  </span>,

                ]}
              >
                <List.Item.Meta
                  title={<a href={`/example/${item.slug}`}>{item.title}</a>}
                  description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
                />

              </List.Item>
            )}
          />
        </Col>
        <Col span={7} offset={1} >

          <Row>
            <Col>
              <Card
                hoverable
                cover={
                  <img alt="avatar" src={this.state.avatar} />
                }
              >
                <Meta
                  title={<Title level={4}>@ {this.props.profile.user}</Title>}
                  description={
                    <div>
                      <div>
                        {this.props.profile.website ? <Text> {this.props.profile.website} <br /></Text> : null}
                        {this.props.profile.location ? <Text> {this.props.profile.location} </Text>: null}
                      </div>
                      <Divider />
                      <div style={{ marginTop: 20}}>
                      {
                        this.props.profile.profile_introduction && this.props.profile.profile_introduction !== "undefined" ?
                        <Paragraph ellipsis={{ rows: 3, expandable: true }} type="secondary">
                          {this.props.profile.profile_introduction}
                        </Paragraph>
                        :
                        <Text> Usuario misterioso </Text>
                      }
                      </div>
                    </div>
                  }
                />

              </Card>
            </Col>
          </Row>

          <Row style={{ marginTop: 30}}>
            <Row>

              <Col span={8}>
                <Statistic title="Prestigio" value={this.props.profile.interaction_rep} prefix={<Icon style={{ padding: 5}} type="thunderbolt" theme="twoTone" twoToneColor="#f67177" />} />
              </Col>

              <Col span={8}>
                <Statistic title="Publicaciones totales" value={`${this.props.profile.count_core_content_posted + this.props.profile.count_example_content_posted}`} prefix={<Icon style={{ padding: 5}} type="edit" theme="twoTone" twoToneColor="#f67177" />} />
              </Col>

              <Col span={8}>
                <Statistic title="Puntaje general" value={`${this.props.profile.apprentice_rep + this.props.profile.professor_rep}`} prefix={<Icon style={{ padding: 5}} type="trophy" theme="twoTone" twoToneColor="#f67177" />} />
              </Col>

            </Row>
          </Row>

          <Row style={{ marginTop: 30}}>
            <Row>
              Experiencia de aprendizaje:
              <Col>
                <Progress percent={(this.props.profile.apprentice_rep/1000)*100} showInfo={false} />
              </Col>


            </Row>

            <Row style={{ marginTop: 20 }}>
              Experiencia de profesor:
              <Col>
                <Progress percent={(this.props.profile.professor_rep/1000)*100} showInfo={false} />
              </Col>
            </Row>
          </Row>

        </Col>
      </Row>
    );
  };

}

export default Profile;
