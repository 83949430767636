import React from 'react';

import { Button, Card, Col, Divider, Icon, Row, Tooltip, Typography} from 'antd';

import ReactPlayer from 'react-player';



const { Paragraph } = Typography;

class ExampleDetailResponsive extends React.Component{

  render() {

    return(
      <div>
        {
          this.props.loggedUser === this.props.example.author ?
          <div>
            <Row>
              <Col>
                <Button href='/example/' type="primary" block> Explora otros cursos prácticos </Button>
              </Col>
            </Row>

            <Row style={{marginTop: 20}}>
                <Col span={11}>
                  <Button href={`/example/${this.props.slug}/update/`} block> Actualizar </Button>
                </Col>
                <Col span={11} offset={2}>
                  <Button onClick={this.props.showDeleteConfirm} type="danger" block> Eliminar </Button>
                </Col>
            </Row>
            <Divider />
          </div>
          :
          <div>
          <Row>
            <Col>
              <Button href='/example/'  type="primary" block> Explora otros cursos prácticos </Button>
            </Col>
          </Row>
            <Divider />
          </div>
        }

        <Row>
          <Col>
            <Card
              hoverable={true}
              bordered={true}
              actions={
                [

                  <Tooltip title="Divertido">
                    <span>
                       <Icon style={{ marginRight: 8, fontSize: '20px' }} type="smile" theme="twoTone" twoToneColor="#f67177" onClick={this.props.funHandler} key="list-vertical-smile"/>{this.props.fun}
                    </span> </Tooltip>,
                  <Tooltip title="No divertido">
                    <span>
                       <Icon style={{ marginRight: 8, fontSize: '20px' }} type="frown" theme="twoTone" twoToneColor="#f67177" onClick={this.props.nofunHandler} key="list-vertical-frown"/>{this.props.no_fun}
                    </span> </Tooltip>,
                  <Tooltip title="Eficiente">
                   <span>
                       <Icon style={{ marginRight: 8, fontSize: '20px' }} type="star" theme="twoTone" twoToneColor="#f67177" onClick={this.props.efficientHandler} key="list-vertical-star" />{this.props.efficient}
                   </span> </Tooltip>,
                  <Tooltip title="Útil">
                   <span>
                       <Icon style={{ marginRight: 8, fontSize: '20px' }} type="bulb" theme="twoTone" twoToneColor="#f67177" onClick={this.props.usefulHandler} key="list-vertical-bulb" />{this.props.useful}
                   </span> </Tooltip>,

                  <Tooltip title="Vistas totales">
                    <span>
                       <Icon style={{ marginRight: 8, fontSize: '20px' }} type="eye" theme="twoTone" twoToneColor="#f67177" key="list-vertical-eye" />{this.props.example.total_views}
                    </span>
                  </Tooltip>

                ]}
            >
              <h1 style={{ marginTop: 10 }}>{this.props.example.title}</h1>
              <small> Autor: { this.props.example.author } | Publicado en: { this.props.example.publish } </small>
              <Divider />

              <ReactPlayer
                url={this.props.example.video}
                playing={false}
                controls={true}
                height='200'
                width='300'
              />


            </Card>
            <Card
              hoverable={true}
              bordered={true}
              style={{marginTop: 20}}
            >
              <h4>Descripción del curso:</h4>
              <Paragraph ellipsis={{ rows: 2, expandable: true }}>
                {this.props.example.description}
              </Paragraph>
            </Card>
          </Col>


        </Row>

      </div>
    );
  };

}

export default ExampleDetailResponsive;
