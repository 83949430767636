import React from 'react';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import MediaQuery from 'react-responsive';

import ExampleContent from '../../components/Content/Example/Example';
import ExampleContentResponsive from '../../components/Content/Example/ExampleResponsive';
import './ExampleContent.css';

class ExampleContentList extends React.Component {

  state = {
    content: []
  }

  componentDidMount() {
      axios.get(`${apiHost}/api/v1/example/`)
        .then(res => {
          this.setState({
            content: res.data
          });
        })
  }

  render() {
    return (
      <MediaQuery minDeviceWidth={1024}>
      {(matches) =>
      matches
        ? <ExampleContent data={this.state.content}/>
        : <ExampleContentResponsive data={this.state.content}/>
      }
      </MediaQuery>

    )
  }

}

export default ExampleContentList
