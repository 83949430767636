import React from 'react';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import MediaQuery from 'react-responsive'
import HomeContent from '../../components/Content/Home';
import HomeContentResponsive from '../../components/Content/HomeResponsive';

class ContentList extends React.Component {

  state = {
    core: [],
    example: []
  };

  componentDidMount() {

    axios.all([
      axios.get(`${apiHost}/api/v1/core/`),
      axios.get(`${apiHost}/api/v1/example/`)
    ])
    .then(axios.spread((core, example) => {

      this.setState({
        core: core.data,
        example: example.data
      })
    }));

  }

  render() {
    return (
      <MediaQuery minDeviceWidth={1024}>
        {(matches) =>
        matches
          ? <HomeContent
              core={this.state.core}
              example={this.state.example}
            />
          : <HomeContentResponsive
              core={this.state.core}
              example={this.state.example}
            />
        }
      </MediaQuery>

    )
  }
}

export default ContentList
