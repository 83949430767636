import React from 'react';
export const Content131DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper k8s3b2ik4e-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>En Desarrollo</p>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>Para más información enviar correo a info@panzofi.com</p>
          </span>
        ),
        className: 'title-content',
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper k8s2yc5gh-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <br />
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
};
