import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../store/actions/auth';

import axios from 'axios';
import { apiHost, client } from '../../config.dev.js';

import MediaQuery from 'react-responsive';

import { Button, Col, Divider, Drawer, Icon, Input, Layout, Row } from 'antd';

import Bubble from './Bubble';

const { Header } = Layout;
const { Search } = Input;

class Navbar extends React.Component {

  state = {
    menuVisible: false,
    searchVisible: false,
    exists: true,
    avatar: '',
    slug: ''
  };

  componentDidMount() {

      axios.get(`${apiHost}/api/v1/profile/`)
        .then(res => {
          let exists = false;
          let avatar = '';
          let slug = '';
          res.data.forEach(item => {
            if(item.username === localStorage.getItem('username')) {
              avatar = item.profile_picture;
              slug = item.slug;
              exists = true;
            };
          });

          if(!exists) {
              this.setState({
                exists: exists
              })
          }

          if(avatar !== null && avatar !== '') {
            this.setState({
              avatar: avatar
            });
          }

          this.setState({
            slug: slug
          });

        });

  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.avatar !==  this.state.avatar  ||  prevProps !== this.props) {
      this.componentDidMount();
      return true;
    };
    return false;
  }

  toggle = () => {
    this.props.toggle();
  };

  /*Add functionality to read keyword form the URL*/
  search = (value, event) => {

    event.preventDefault();

    if(value) {
      this.props.history.push({
        pathname: `/search/${value}`,
        keyword: value
      });
    } else {
      this.props.history.push('/')
    }

  };

  upload = () => {
    window.location.href = `${client}/upload/assist/`;
  };

  /* Responsive design drawer functions*/
  logout = () => {
    this.props.logout()
  };

  showMenuDrawer = () => {
    this.setState({
      menuVisible: true,
    });
  };

  onMenuClose = () => {
    this.setState({
      menuVisible: false,
    });
  };

  showSearchDrawer = () => {
    this.setState({
      searchVisible: true,
    });
  };

  onSearchClose = () => {
    this.setState({
      searchVisible: false,
    });
  };

  profile = () => {
    if(!this.state.exists) {
      this.props.history.push({
        pathname:'/profile/new/create/'
      });
    }
    if(this.state.slug) {
      this.props.history.push({
          pathname: `/profile/${this.state.slug}`,
          keyword: this.state.slug
      });
    }

  }

  settings = () => {

    if(!this.state.exists) {
      this.props.history.push({
        pathname:'/profile/new/create/'
      });
    }

    if(this.state.exists && localStorage.getItem('username')) {
      this.props.history.push({
        pathname: `/settings/${this.state.slug}`,
        keyword: this.state.slug
      });
    }
  }

  render(){

    return(

      <Header style={{ background: '#fff'}}>

       {
         this.props.base.isAuthenticated ?
         <Drawer
            title={`Hi! ${localStorage.getItem('username')}`}
            placement="left"
            width="70%"
            closable={true}
            onClose={this.onMenuClose}
            visible={this.state.menuVisible}
          >

          <p> Explora nuestro catálogo</p>
          <ul>
            <li><a href='/' style={{color:"#9999A1"}}>Inicio</a><br /></li>
            <li><a href='/core' style={{color:"#9999A1"}}>Teórico</a><br /></li>
            <li><a href='/example' style={{color:"#9999A1"}}>Práctico</a><br /></li>
          </ul>
          <Divider />
          <p> ¿Tienes contenido interesante? Sube un curso</p>
          <ul>
            <li><a href='/upload/assist/' style={{color:"#9999A1"}}>Subir</a><br /></li>
          </ul>
          <Divider />
          <p> Acceso a cuenta </p>
          <ul>
            <li><a onClick={this.profile} style={{color:"#9999A1"}}> Perfil </a><br /></li>
            <li><a onClick={this.settings} style={{color:"#9999A1"}}> Ajustes </a><br /></li>
          </ul>
          <Divider />

          <Divider />
          <Button type="default" onClick={this.logout} block>Desconectar</Button>



          </Drawer>
         :
         <Drawer
            title="Menú"
            placement="left"
            width="70%"
            closable={true}
            onClose={this.onMenuClose}
            visible={this.state.menuVisible}
          >
          <p> Explora nuestro catálogo</p>
          <ul>
            <li><a href='/' style={{color:"#9999A1"}}>Inicio</a><br /></li>
            <li><a href='/core' style={{color:"#9999A1"}}>Teórico</a><br /></li>
            <li><a href='/example' style={{color:"#9999A1"}}>Práctico</a><br /></li>
          </ul>
          <Divider />
          <p> ¿Tienes contenido interesante? Sube un curso </p>
          <ul>
            <li><a href='/upload/assist/' style={{color:"#9999A1"}}>Subir</a><br /></li>
          </ul>

          <Divider />
          <Button type="primary" href='/signup' block>Únete a Panzofi</Button>
          <Divider>o</Divider>
          <Button type="default" href='/login' block>Iniciar sesión</Button>
          <Divider />
          <p> Información Adicional</p>
          <ul>
            <li><a href='/about/' style={{color:"#9999A1"}}>Sobre Nosotros</a><br /></li>
            <li><a href='/business/' style={{color:"#9999A1"}}>Empresas</a><br /></li>
            <li><a href='/teachers/' style={{color:"#9999A1"}}>Docentes</a><br /></li>
            <li><a href='/institutionedu/' style={{color:"#9999A1"}}>Instituciones de Educación</a><br /></li>
            <li><a href='/career/' style={{color:"#9999A1"}}>Trabaja con nosotros</a><br /></li>
            <li><a href='/support/' style={{color:"#9999A1"}}>Contacto</a><br /></li>
          </ul>
          


          </Drawer>
       }
        <Drawer
            title="Explorar el catálogo"
            placement="right"
            width="70%"
            closable={true}
            onClose={this.onSearchClose}
            visible={this.state.searchVisible}
          >
          <p> ¿Buscas un curso en particular para estudiar? Busca en nuestro catálogo de cursos.</p>
          <Search
             className="search"
             placeholder="buscar cursos ..."
             onSearch={(value, event) => this.search(value, event)}
             allowClear
             width="100%"
          />
          <Divider />
          <p>¿No encontraste lo que estabas buscando? ¿Por qué no subes tu propio curso?</p>
          <Button type="primary" href='/upload/assist/' block>Subir</Button>
        </Drawer>

        <MediaQuery minDeviceWidth={1024}>
          {(matches) =>
          matches
            ? <Row className="header-inner-xl">
              <Col span={2}>
                <Icon
                  className="trigger"
                  type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                  onClick={this.toggle}
                />
              </Col>
              <Col span={4} style={{ marginTop: 20}}>
                <img src="/logo v2.png" alt="logo" align="left"/>
              </Col>
              <Col span={15}>
                <Col span={18}>
                <Search
                   className="search"
                   placeholder="buscas cursos interesantes ..."
                   onSearch={(value, event) => this.search(value, event)}
                   allowClear
                />
                </Col>
                <Col span={6}>
                  <Button style={{marginLeft: 10}} onClick={this.upload} type="primary" icon="cloud-upload" size="default">
                    Subir
                  </Button>
                </Col>

              </Col>
              <Col span={3}>
                <Bubble {...this.props} isLoggedIn={this.props.base.isAuthenticated} />
              </Col>
              </Row>
            : <Row className="header-inner-xs">
              <Col xs={{ span: 1, offset: 0 }}>
                <Icon className="menu-header-inner-xs" type="menu" onClick={this.showMenuDrawer} />
              </Col>
              <Col xs={{ span: 19, offset: 2 }}>
                <img className="logo-header-inner-xs" src="/logo v2.png" alt="logo" align="center"/>
              </Col>
              <Col xs={{ span: 1, offset: 1 }}>
                <Icon className="search-header-inner-xs" type="search" onClick={this.showSearchDrawer}/>
              </Col>
              </Row>
          }
        </MediaQuery>


      </Header>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout())
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Navbar));
