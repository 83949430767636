import React from 'react';

import 'antd/dist/antd.css';
import './Layout.css';

import { Layout, Modal } from 'antd';

import Sidebar from './Siderbar';
import Navbar from './Navbar';
import Footer from './Footer';

import ReactPlayer from 'react-player';

const { Content } = Layout;

class Base extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      collapsed: true,
      cookieSet: true,
      viewPopup: false,
    }
  }

  componentDidMount() {
      let panzofi_visit_cookie = localStorage["visit_cookie"];
      if(panzofi_visit_cookie) {
           this.setState({ viewPopup: false })
      } else {
        if(!localStorage.getItem("username")) {
         window.setTimeout (this.cookieConsent, 3000);
         this.setState({ viewPopup: true});
         window.setTimeout (this.info, 10000);
       }
      }
  }

  info = () => {
  Modal.info({
      title: "Parece que eres nuev@ aquí, aquí hay un vídeo tutorial para ti",
      content: (
        <div class="intro-modal">
          <p>Te ayudará a comenzar con Panzofi</p>
          <br/>

          <ReactPlayer
            class="intro-modal"
            url={`https://www.youtube.com/watch?v=yf1ZzmJVxvM`}
            playing={false}
            controls={true}
            controlsList = {"nodownload"}
            height={600}
            width='100%'
          />

        </div>
      ),
      width: 900,
      height: 600,
      zIndex: 9000,
      centered: true,
      okText:"Luego",
      onOk() {}
    });
  }

  cookieConsent = () => {
    Modal.confirm({
      title: 'Este sitio usa cookies',
      width: 900,
      zIndex: 2000,
      top: 250,
      content: (
        <div>
          <p>Utilizamos cookies para garantizar que obtengas la mejor experiencia. Al utilizar nuestro sitio web, aceptas nuestra Política de cookies.</p>
        </div>
      ),
      okText: "Aceptar",
      cancelText: "Rechazar",
      onOk() {
        localStorage["visit_cookie"] = true;
      },
    });
  }



  toggle = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  render() {

    return (
      <React.Fragment>

        <Layout>


          <Sidebar collapsed={this.state.collapsed} className="siderbar"/>

          <Layout>

            <Navbar base={this.props} toggle={this.toggle} collapsed={this.state.collapsed} />

            <Content
              style={{
                padding: 10,
                background: "#fff",
                minHeight: 300,
                fontSize: 13,
                marginBottom: 20
              }}
            >
                {this.props.children}
            </Content>

            <Footer value={this.props.locale} callback={this.props.changeLocale}/>

          </Layout>

        </Layout>
      </React.Fragment>

    );
  }
}

export default Base;
