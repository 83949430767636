import React from 'react';

import MediaQuery from 'react-responsive';

import { Button, Col, Form, Icon, Input, Row, Spin, Typography } from 'antd';

import SimpleMDEReact from "react-simplemde-editor";
import "easymde/dist/easymde.min.css";

const { TextArea } = Input;
const { Text } = Typography;

const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class TextArticle extends React.Component {

  state = {
    textArticle: this.props.init_content.text_article
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    let updateContent = {
      category:'default',
      title:'default',
      description:'default',
      thumbnail:'default',
      infographics:'default',
      video:'default',
      audio:'default',
      textArticle: this.state.textArticle,
      previous: 'default',
      next:'default',

    }
    this.props.updateContent(updateContent);
  }

  handleTextArticleChange = value => {
    this.setState({
      textArticle: value
    });
  };

  render() {

    return(
      <React.Fragment>
      {
        this.props.sending?
        <div className="loader">
          <Spin tip="Gran ejemplo! Espera mientras validamos tu contenido en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
        </div>
        :
        <Form onSubmit={(event) => this.handleFormSubmit(event)}>

        <Text strong>Escribe o actualiza un el texto para tu curso</Text> <br />
        <Form.Item style={{marginTop: 20}}>

        <SimpleMDEReact
          className={""}
          label="Funcionalidades básicas para el estilo de tu texto"
          value={this.state.textArticle}
          onChange={this.handleTextArticleChange}
        />

        </Form.Item>

        <Form.Item>
        <MediaQuery minDeviceWidth={768}>
          {(matches) =>
          matches
            ? <Row>
              <Col span={5}>
                <Button type="primary" htmlType="submit" >Actualización del curso</Button>
              </Col>
              <Col span={4} offset={1}>
                <Button type="default" href={`/core/${this.props.init_content.slug}`} >Ver curso</Button>
              </Col>
            </Row>
            : <div>
              <Row>
                <Col>
                  <Button type="primary" htmlType="submit" block>Actualización del curso</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="default" href={`/core/${this.props.init_content.slug}`} block>Ver curso</Button>
                </Col>
              </Row>
            </div>
          }
        </MediaQuery>
        </Form.Item>
        </Form>
      }

      </React.Fragment>
    );
  }
}

export default TextArticle;
