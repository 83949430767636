import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 k8njfjss9f7-editor_css' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Estamos cambiando la forma de capacitar empleados</p>
          </span>
        ),
        className: 'banner5-title k8nj1osr8-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain k8nj1xssd8-editor_css',
        children: (
          <span>
            <p>
              Te ofrecemos los mejores cursos y la mejor plataforma para que
              capacitar a tu empresa
            </p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://forms.gle/s35EhfZ3pt5mXTYn6',
          className: 'banner5-button k8nj5r7wq6-editor_css',
          type: 'primary',
          children: (
            <span>
              <p>Demo gratuita</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: 'https://s3.amazonaws.com/panzofi.com/Banner_empresas.svg',
  },
};
export const Feature70DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature7-wrapper k8njgi5uhhn-editor_css',
  },
  page: { className: 'home-page feature7' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1 k8nirqq67cj-editor_css',
        children: (
          <span>
            <p>Más de 50 cursos para maximizar el talento</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature7-title-content k8nis0d6vhd-editor_css',
        children: (
          <span>
            <p>
              Los mejores cursos para tus empleados y una plataforma que permite
              que la curva de aprendizaje sea más rápida. El activo más valioso
              de cada empresa es el recurso humano
            </p>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8njqkvczl-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8niqlmxj99-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nj89dib7f-editor_css',
              children: (
                <span>
                  <p>Ventas</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8niw1fd8mq-editor_css',
              children: (
                <span>
                  <p>
                    Estrategias, desarrollo de territorio, negociación,
                    marketing...
                  </p>
                </span>
              ),
            },
          ],
          href: 'https://forms.gle/3JexBDPgwDRS6NPB9',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8njqzqn1f9-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8niqqcuc9p-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nj8d8equ-editor_css',
              children: (
                <span>
                  <p>Productividad</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nivri2y3-editor_css',
              children: (
                <span>
                  <p>
                    Administración del tiempo, gestión de tareas, prioritizar...
                  </p>
                </span>
              ),
            },
          ],
          href: 'https://forms.gle/3JexBDPgwDRS6NPB9',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8njr8saep-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8niqs605ns-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nj8hkg9v-editor_css',
              children: (
                <span>
                  <p>Innovación</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nivew5877-editor_css',
              children: (
                <span>
                  <p>Design Thinking, Canvas, investigación...</p>
                </span>
              ),
            },
          ],
          href: 'https://forms.gle/3JexBDPgwDRS6NPB9',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8njrhb831-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8niqu1nckh-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nj8l7nb-editor_css',
              children: (
                <span>
                  <span>
                    <p>
                      <span>Transformación digital</span>
                      <br />
                    </p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nite3thsf-editor_css',
              children: (
                <span>
                  <p>
                    Big Data, robótica, optimización, analíticos, inteligencia
                    artificial...{' '}
                  </p>
                </span>
              ),
            },
          ],
          href: 'https://forms.gle/3JexBDPgwDRS6NPB9',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8njroeadte-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8nir2ezc2r-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nj8qo6ijb-editor_css',
              children: (
                <span>
                  <p>Liderazgo</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8niwcoxeo-editor_css',
              children: (
                <span>
                  <p>
                    Cultura organizacional, gestión de equipos, alto
                    rendimiento...
                  </p>
                </span>
              ),
            },
          ],
          href: 'https://forms.gle/3JexBDPgwDRS6NPB9',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8njrzdm7rp-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8nir0gd936-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nj8uj9tma-editor_css',
              children: (
                <span>
                  <p>Emprendimiento</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8niwp6322-editor_css',
              children: (
                <span>
                  <span>
                    <p>MVP, estrategias de negocios, escalabilidad...</p>
                  </span>
                </span>
              ),
            },
          ],
          href: 'https://forms.gle/3JexBDPgwDRS6NPB9',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8njs73a07l-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8niqyrpuqh-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nj8yd3dw-editor_css',
              children: (
                <span>
                  <p>Finanzas</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8niwy6krr-editor_css',
              children: (
                <span>
                  <p>
                    Contabilidad, gestión financiera, análisis financiero...
                  </p>
                </span>
              ),
            },
          ],
          href: 'https://forms.gle/3JexBDPgwDRS6NPB9',
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group k8njsf6zlm-editor_css',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image k8niqwmx0q-editor_css',
              children:
                'https://gw.alipayobjects.com/zos/basement_prod/e339fc34-b022-4cde-9607-675ca9e05231.svg',
            },
            {
              name: 'title',
              className: 'feature7-block-title k8nj921w3fe-editor_css',
              children: (
                <span>
                  <p>Y mucho más</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content k8nixgnq3lq-editor_css',
              children: (
                <span>
                  <p>Estamos creado cursos continuamente, solicita info...</p>
                </span>
              ),
            },
          ],
          href: 'https://forms.gle/3JexBDPgwDRS6NPB9',
        },
      },
    ],
  },
};
export const Pricing10DataSource = {
  wrapper: {
    className: 'home-page-wrapper pricing1-wrapper k8njgry6l5a-editor_css',
  },
  page: { className: 'home-page pricing1' },
  OverPack: { playScale: 0.3, className: 'pricing1-content-wrapper' },
  titleWrapper: {
    className: 'pricing1-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Compara nuestros planes</p>
          </span>
        ),
        className: 'pricing1-title-h1 k8nimrouf9a-editor_css',
      },
    ],
  },
  block: {
    className: 'pricing1-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <p>Gratis</p>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <span>
                  <p>$0 USD</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content k8nihrm52ou-editor_css',
            children: (
              <span>
                <span>
                  <p>Modo usuario</p>
                  <p>*Sin acceso a los cursos privados</p>
                  <p>*Sin acceso a consola de administración</p>
                  <p>Precio mensual por usuario</p>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button k8njzz2c3fe-editor_css',
                href: 'https://www.panzofi.com/core/como-funciona-panzofi',
                children: 'Más información',
              },
            },
          },
        },
      },
      {
        name: 'block1',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: {
            className: 'pricing1-block-box active k8nifakl0he-editor_css',
          },
          topWrapper: {
            className: 'pricing1-top-wrapper k8nidjutwo-editor_css',
          },
          name: {
            className: 'pricing1-name',
            children: (
              <span>
                <p>Básico</p>
              </span>
            ),
          },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <span>
                  <span>
                    <p>$19 USD</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content k8nibpa907-editor_css',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>Empresas con menos de 500 empleados</p>
                      <p>Consola de administración</p>
                      <p>Analíticos básico</p>
                      <p>Precio mensual por usuario</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button k8nid2z66pq-editor_css',
                href: 'https://forms.gle/ZqRy1PKdJan2Lopt8',
                children: 'Comprar',
              },
            },
          },
        },
      },
      {
        name: 'block2',
        className: 'pricing1-block',
        md: 8,
        xs: 24,
        children: {
          wrapper: { className: 'pricing1-block-box ' },
          topWrapper: { className: 'pricing1-top-wrapper' },
          name: { className: 'pricing1-name', children: 'Pro' },
          money: {
            className: 'pricing1-money',
            children: (
              <span>
                <span>
                  <p>Contáctanos</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'pricing1-content k8nic8y61nt-editor_css',
            children: (
              <span>
                <span>
                  <p>Empresas con más de 500 empleados</p>
                  <p>Consola de administración</p>
                  <p>Analíticos Pro</p>
                  <p>Consultoría</p>
                  <p>Soporte</p>
                </span>
              </span>
            ),
          },
          line: { className: 'pricing1-line' },
          buttonWrapper: {
            className: 'pricing1-button-wrapper',
            children: {
              a: {
                className: 'pricing1-button k8nk0fknhl-editor_css',
                href: 'https://forms.gle/MCR7fu6Sv5LXC53v5',
                children: 'Más información',
              },
            },
          },
        },
      },
    ],
  },
};
export const Content50DataSource = {
  wrapper: {
    className: 'home-page-wrapper content5-wrapper k8njh7jfvqk-editor_css',
  },
  page: { className: 'home-page content5 k8ni4t1hfy-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Nuestros casos de éxito</p>
            </span>
          </span>
        ),
        className: 'title-h1 k8ni3ei0lsp-editor_css',
      },
    ],
  },
  block: {
    className: 'content5-img-wrapper',
    gutter: 16,
    children: [
      {
        name: 'block0',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s3.amazonaws.com/panzofi.com/UserCase1.svg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>THE PLACE</p>
                </span>
              </span>
            ),
            className: 'k8nj9l5c1ra-editor_css',
          },
        },
      },
      {
        name: 'block1',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s3.amazonaws.com/panzofi.com/UserCase2.svg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>ISCON</p>
                </span>
              </span>
            ),
            className: 'k8nj9oicpt-editor_css',
          },
        },
      },
      {
        name: 'block2',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s3.amazonaws.com/panzofi.com/UserCase3.svg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>EL MONKIKING</p>
                </span>
              </span>
            ),
            className: 'k8nj9s3cuju-editor_css',
          },
        },
      },
      {
        name: 'block3',
        className: 'block',
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: 'content5-block-content' },
          img: {
            children: 'https://s3.amazonaws.com/panzofi.com/UserCase4.svg',
          },
          content: {
            children: (
              <span>
                <span>
                  <p>NACHOS BAR</p>
                </span>
              </span>
            ),
            className: 'k8nj9w7y0od-editor_css',
          },
        },
      },
    ],
  },
};
export const Content40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content4-wrapper k8njhswmil7-editor_css',
  },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Entrenamiento con los mejores estándares</p>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content k8ninozcv9-editor_css',
        children: (
          <span>
            <p>
              Nuestros instructores son los mejores en la industria y tienen
              años de experiencia
            </p>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video:
        'https://s3.amazonaws.com/panzofi.com/Video_panzofi_30001-2559.mp4',
      image: 'https://s3.amazonaws.com/panzofi.com/video-ima-ini.svg',
    },
  },
};
