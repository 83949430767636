import React from 'react';

import { List, Avatar, Icon, Typography } from 'antd';

const { Title, Paragraph } = Typography;

class CategoryContent extends React.Component {

  render() {
    let src = "";
    return(
      <React.Fragment>

        <Title level={4}> Contenido teórico publicado</Title>
        <List
          itemLayout="vertical"
          size="default"
          dataSource={this.props.core}
          renderItem={item => (
            <List.Item
              key={item.title}
              actions={[
                <span><Icon
                  style={{ marginRight: 8, paddingLeft: 30 }}
                  type="edit" theme="twoTone" twoToneColor="#f67177" key="list-vertical-edit" /> Autor: {item.author}
                </span>,

                <span><Icon
                  style={{ marginRight: 8 }}
                   type="calendar" theme="twoTone" twoToneColor="#f67177" key="list-vertical-calendar" /> Publicado en: {item.publish}
                </span>,

                <span><Icon
                  style={{ marginRight: 8 }}
                  type="eye" theme="twoTone" twoToneColor="#f67177" key="list-vertical-eye" />Vistas totales: {item.total_views}
                </span>,

              ]}
              extra={
                <img
                  width={272}
                  height={145}
                  alt = "" //"Doofus no thumbnail here!"
                  src={item.thumbnail}
                />
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar src={typeof item.author_avatar[0] === 'undefined' ?
                            src="https://s3.amazonaws.com/panzofi.com/Avatar.png"
                          : src=`${item.author_avatar[0]["profile_picture"]}`}
                  />
                }
                title={<a href={`/core/${item.slug}`}>{item.title}</a>}
                description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
              />

            </List.Item>
          )}
        />
        <Title level={4} style={{marginTop: 20}}> Contenido práctico publicado</Title>
        <List
          itemLayout="vertical"
          size="default"
          dataSource={this.props.example}
          renderItem={item => (
            <List.Item
              key={item.title}
              actions={[
                <span><Icon
                  style={{ marginRight: 8, paddingLeft: 30 }}
                  type="edit" theme="twoTone" twoToneColor="#f67177" key="list-vertical-edit" /> Autor: {item.author}
                </span>,

                <span><Icon
                  style={{ marginRight: 8 }}
                   type="calendar" theme="twoTone" twoToneColor="#f67177" key="list-vertical-calendar" /> Publicado en: {item.publish}
                </span>,

                <span><Icon
                  style={{ marginRight: 8 }}
                  type="eye" theme="twoTone" twoToneColor="#f67177" key="list-vertical-eye" />Vistas totales: {item.total_views}
                </span>,

              ]}
              extra={
                <img
                  width={272}
                  height={145}
                  alt = "" //"Doofus no thumbnail here!"
                  src={item.thumbnail}
                />
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar src={typeof item.author_avatar[0] === 'undefined' ?
                            src="https://s3.amazonaws.com/panzofi.com/Avatar.png"
                          : src=`${item.author_avatar[0]["profile_picture"]}`}
                  />
                }
                title={<a href={`/example/${item.slug}`}>{item.title}</a>}
                description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
              />

            </List.Item>
          )}
        />

      </React.Fragment>
    );
  };

}

export default CategoryContent;

/*
Use the following url for displaying user avatars
src={`http://localhost:8000${item.author_avatar[0]["profile_picture"]}`}
*/
