import React from 'react';

import cookie from "react-cookies";
import { connect } from 'react-redux';

import { notification } from 'antd';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';
import { loadXHR } from '../../file-processor-api';

import MediaQuery from 'react-responsive';

import ExampleUpdate from '../../components/Content/Example/Update';
import ExampleUpdateResponsive from '../../components/Content/Example/UpdateResponsive';

class ExampleContentUpdate extends React.Component {

  constructor(props){
   super(props);
   this.state = {
     example: [],
     core: [],

     init_content: {},
     update_content: {},

     categories: [],

     category: '',
     title: '',
     description: '',
     thumbnail: '',
     video: '',
     core_relation: [],

     sending: false
   }
  }

  componentDidMount() {
    axios.all([
      axios.get(`${apiHost}/api/v1/example/${this.props.match.params.slug}/`),
      axios.get(`${apiHost}/api/v1/categories/`),
      axios.get(`${apiHost}/api/v1/core/`),
      axios.get(`${apiHost}/api/v1/example/`)
    ])
    .then(axios.spread((init, categories, core, example) => {

      this.setState({
        init_content: init.data,
        categories: categories.data,
        core: core.data,
        example: example.data
      })

      this.setState({
        title: this.state.init_content.title,
        description: this.state.init_content.description
      });

      !this.state.init_content.category ? this.setState({category: ''})
                            : this.state.categories.forEach((category) => {
                                if (category.category === this.state.init_content.category) {
                                  this.setState({
                                    category: category.id
                                  });
                                }
                            });

      !this.state.init_content.thumbnail ? this.setState({thumbnail: ''})
                            : loadXHR(this.state.init_content.thumbnail)
                            .then((blob) => {
                              return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);

                              })
                              .then(data => {
                                this.setState({
                                  thumbnail: data
                                });
                              });
                            });


      !this.state.init_content.video ? this.setState({video: ''})
                            : loadXHR(this.state.init_content.video)
                            .then((blob) => {
                              const file = new File([blob], this.state.init_content.video.split('/')[4].split('?')[0], {lastModified: new Date()});
                              this.setState({
                                video: file
                              });
                            });

      let core_relation = [];
      !this.state.init_content.core_relation ? this.setState({core_relation: null})
                            : this.state.core.forEach((core) => {
                                if(this.state.init_content.core_relation.includes(core.id)) {
                                  core_relation.push(parseInt(core.id, 10));
                                }
                              });
                              this.setState({
                                core_relation
                              });

     console.log("Core relations are:");
     console.log(this.state.core_relation);
    }));
  }

  updateContent = (data) => {

    this.setState({
      sending: !this.state.sending
    });


    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.headers = {
      "Authorization": 'Token '+ this.props.token,
      "X-CSRFTOKEN": cookie.load("csrftoken")
    }

    const temp=[];

    if(data.core_relation !== "default") {

      Object.entries(data.core_relation).forEach(
          ([key, val]) => {
            temp.push(parseInt(val, 10));
          }
      );
    }

    const fd = new FormData();

    data.title === "default" || data.title === undefined? fd.append("title", this.state.title) : fd.append("title", data.title);
    data.description === "default" || data.description === undefined ? fd.append("description", this.state.description) : fd.append("description", data.description);
    data.category === "default" || data.category === undefined ? fd.append("category", this.state.category) : fd.append("category", data.category);
    data.thumbnail === "default" || data.thumbnail === undefined ? fd.append("thumbnail", this.state.thumbnail) : fd.append("thumbnail", data.thumbnail);
    data.video === "default" || data.video === undefined ? fd.append("video", this.state.video) : fd.append("video", data.video);
    data.core_relation === "default" || data.core_relation === undefined ?
                  fd.append("core_relation", this.state.core_relation)
                  : temp.forEach((relation) =>{
                    fd.append("core_relation", relation );
                  });



    return axios.put(`${apiHost}/api/v1/example/${this.props.match.params.slug}/edit/`,fd)
    .then(res => {
      console.log(res);
      if(res.status === 200) {
        notification['success']({
          message: 'Hecho',
          description: '¡Tu curso ha sido actualizado con éxito!'
        });
      };
      this.setState({
        sending: !this.state.sending
      });
      this.componentDidMount();
    })
    .catch(err => {
      console.error(err);
      notification['error']({
        message: 'Error',
        description: '¡El curso no se actualizó!'
      });
      this.setState({
        sending: !this.state.sending
      });
      this.componentDidMount();
    });
  }

  render() {
    return(
      <MediaQuery minDeviceWidth={1024}>
        {(matches) =>
        matches
          ? <ExampleUpdate {...this.state} updateContent={this.updateContent}/>
          : <ExampleUpdateResponsive {...this.state} updateContent={this.updateContent}/>
        }
      </MediaQuery>
    );
  }

}

const mapStateToProps = state => {
  return {
    token: state.token
  }
}

export default connect(mapStateToProps)(ExampleContentUpdate);
