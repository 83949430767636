import React from 'react';

import MediaQuery from 'react-responsive';

import { loadXHR } from '../../../../file-processor-api';

import { Button, Col, Form, Icon, Row, Spin, Typography, Upload } from 'antd';

const { Text } = Typography;
const { Dragger } = Upload;

const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class Video extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        loading: false,
        videoUrl: this.props.init_content.video
    };
  }

  componentDidMount() {
    !this.state.videoUrl ? this.setState({videoUrl: ''})
                          : loadXHR(this.state.videoUrl)
                          .then((blob) => {
                            console.log(this.state.videoUrl.split('/')[4]);
                            const file = new File([blob], this.state.videoUrl.split('/')[4], {lastModified: new Date()});
                            console.log(file);
                            this.setState({
                              videoUrl: file
                            });
                          });

  };

  handleFormSubmit = (event) => {
    event.preventDefault();
    let updateContent = {
      category:'default',
      title:'default',
      description:'default',
      thumbnail:'default',
      video:this.state.videoUrl,
      core_relation:'default',

    };
    this.props.updateContent(updateContent);
  };

  videoUpload = info => {

    if (info.fileList.length > 1 ){
      info.fileList.shift();
    }

    this.setState({videoUrl: info.fileList[0].originFileObj});

  };

  dummyRequest({ file, onSuccess }) {
   setTimeout(() =>{
     onSuccess("ok");
   }, 5);
 };

  render() {
    return(
      <React.Fragment>
      {
        this.props.sending?
        <div className="loader">
          <Spin tip="¡Gran actualización! Espera mientras validamos tu contenido en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
        </div>
       :
        <Form onSubmit={(event) => this.handleFormSubmit(event)}>

          <Text><strong> Arrastra el archivo en el cuadro o haz clic a continuación para actualizar el archivo de vídeo del curso. </strong></Text>

          <Form.Item style={{marginTop: 20, padding: 10}}>
            <Dragger onChange={this.videoUpload}>
              <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
              </p>
              <p className="ant-upload-text">Haz clic o arrastra el archivo a esta área para cargar</p><br />
              <p className="ant-upload-hint">
                Comprime el tamaño del archivo de vídeo antes de subirlo. <br />
                Ten en cuenta que las extensiones válidas incluyen <b> .mp4, .ogv </b> y <b> .webm </b>. Límite de carga <b> 30 MB </b>
              </p>
            </Dragger>

          </Form.Item>

          <br />
          <Form.Item>
          <MediaQuery minDeviceWidth={768}>
            {(matches) =>
            matches
              ? <Row>
                <Col span={5}>
                  <Button type="primary" htmlType="submit" >Actualización del curso</Button>
                </Col>
                <Col span={4} offset={1}>
                  <Button type="default" href={`/example/${this.props.init_content.slug}`} >Ver curso</Button>
                </Col>
              </Row>
              : <div>
                <Row>
                  <Col>
                    <Button type="primary" htmlType="submit" block>Actualización del curso</Button>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button type="default" href={`/example/${this.props.init_content.slug}`} block>Ver curso</Button>
                  </Col>
                </Row>
              </div>
            }
          </MediaQuery>

          </Form.Item>

        </Form>
      }
      </React.Fragment>
    );
  }
}

export default Video;
