import React from 'react';

import { connect } from 'react-redux';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import CategoryContent from '../../components/Content/Category';

class CategoryContentList extends React.Component {

  state = {
    core: [],
    example: []
  };

  componentDidMount() {

    axios.all([
      axios.get(`${apiHost}/api/v1/core/`),
      axios.get(`${apiHost}/api/v1/example/`)
    ])
    .then(axios.spread((core, example) => {

      const slug = this.props.location.pathname.split('/category/')[1];
      axios.get(`${apiHost}/api/v1/categories/${slug}/`)
      .then(res => {

        let coreTemp = [];
        let exampleTemp = [];

        core.data.forEach((content) => {
          if(content.category === res.data.category) {
            coreTemp.push(content);
          }
        })

        example.data.forEach((content) => {
          if(content.category === res.data.category) {
            exampleTemp.push(content);
          }
        })

        this.setState({
          core: coreTemp,
          example: exampleTemp
        })
      });

    }));

  }

  shouldComponentUpdate(prevProps, prevState) {
    if(this.props.location.pathname !== prevProps.location.pathname){
      this.componentDidMount();
    }
    return true
  }

  render() {
    return (
      <CategoryContent {...this.state} />
    );
  }
}

export default connect(null)(CategoryContentList);
