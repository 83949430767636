import React from 'react';

import MediaQuery from 'react-responsive';

import { beforeUpload, getBase64 } from '../../../file-processor-api';

import { Button, Col, Form, Icon, Row, Spin, Upload, Typography } from 'antd';

const { Text, Title } = Typography;
const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class Avatar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      profile_picture: this.props.profile.profile_picture
    }
  }


  handleSubmit = (event) => {
    event.preventDefault();
    let updateProfile = {
      profile_introduction: 'default',
      location: 'default',
      website: 'default',
      phone: 'default',
      profile_picture: this.state.profile_picture
    }
    this.props.updateProfile(updateProfile);
  };

  handleChange = info => {
     if (info.file.status === 'uploading') {
       this.setState({ loading: true });
       return;
     }
     if (info.file.status === 'done') {
       // Get this url from response in real world.
       getBase64(info.file.originFileObj, profile_picture => {
         this.setState({
           profile_picture,
           loading: false,
         });
       });
     }
   };

  render() {

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Subir</div>
      </div>
    );
    const { profile_picture } = this.state;

    return(
        <React.Fragment>
        {
          this.props.sending?
          <div className="loader">
            <Spin tip="¡Gran actualización! Espera mientras validamos tu perfil en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
          </div>
          :
          <div>

            <Title level={3}>Configuraciones de avatar</Title>

            <Form style={{marginTop: 50}} onSubmit={ (event) => this.handleSubmit(event)}>

              <Text>Haz clic a continuación para actualizar tu foto de perfil. El tipo admitido incluye <i> jpeg </i> y <i> png </i>. Límite máximo de carga <i> 2MB </i></Text>

              <Form.Item style={{marginTop: 20, padding: 10}}>
              <Upload
                name="Avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                beforeUpload={beforeUpload}
                onChange={this.handleChange}
              >
                {profile_picture ? <img src={profile_picture} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
              </Upload>
              </Form.Item>

              <Form.Item>
                <MediaQuery minDeviceWidth={768}>
                  {(matches) =>
                  matches
                    ? <Row>
                      <Col span={4}>
                        <Button type="primary" htmlType="submit" >Crear perfil</Button>
                      </Col>
                      <Col span={4} offset={1}>
                        <Button type="default" href={`/`} >Volver al inicio</Button>
                      </Col>
                    </Row>
                    : <div>
                      <Row>
                        <Col>
                          <Button type="primary" htmlType="submit" block>Crear perfil</Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button type="default" href={`/`} block>Volver al inicio</Button>
                        </Col>
                      </Row>
                    </div>
                  }
                </MediaQuery>
              </Form.Item>

            </Form>

          </div>
        }
        </React.Fragment>
      );
    };

};

export default Avatar;
