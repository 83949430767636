import React from 'react';

import cookie from "react-cookies";
import { connect } from 'react-redux';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import { notification } from 'antd';

import CreateProfile from '../../components/User/Settings/Create';

class ProfileCreate extends React.Component {

  createProfile = (profile) => {
    const openNotification = (type, msg, des) => {
      notification[type]({
        message: msg,
        description: des
      });
    };

    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.headers = {
       "Authorization": 'Token '+ this.props.token,
       "X-CSRFTOKEN": cookie.load("csrftoken")
    }

    const fd = new FormData();

    !profile.introduction ? fd.append('profile_introduction', '') : fd.append('profile_introduction', profile.introduction)
    !profile.location ? fd.append('location', '') : fd.append('location', profile.location)
    !profile.website ? fd.append('website', '') : fd.append('website', profile.website)
    !profile.phone ? fd.append('phone', '') : fd.append('phone', profile.phone)
    !profile.imageUrl ? fd.append('profile_picture', '') : fd.append('profile_picture', profile.imageUrl )


    return axios.post(`${apiHost}/api/v1/profile/create/`, fd)
    .then(res=> {
       if(res.status === 201) {
         openNotification('success','Hecho',' El perfil se creó correctamente. Cierre sesión y vuelva a iniciar sesión para asegurarse de que todos los cambios se reflejen en la plataforma')
       }
     })
    .catch(err => {
      openNotification('error','Error de red',' Error al crear el perfil')
    });

  }

  render() {
    return(
      <CreateProfile create={this.createProfile} />
    );
  };
}

const mapStateToProps = state => {
  return {
    token: state.token
  }
}

export default connect(mapStateToProps)(ProfileCreate);
