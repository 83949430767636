import React from 'react';

import axios from 'axios';
import cookie from "react-cookies";
import { connect } from 'react-redux';

import { apiHost } from '../../config.dev.js';
import { beforeUpload, getBase64 } from '../../file-processor-api';

import MediaQuery from 'react-responsive';

import { Col, Button, Form, Icon, Input, notification, Row, Result, Select, Spin, Steps, Typography, Upload } from 'antd';

import './UploadContent.css';

const { Dragger } = Upload;
const { Option } = Select;
const { Step } = Steps;
const { TextArea } = Input;
const { Text, Title } = Typography;

const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;
const loggedUser = localStorage.getItem('username');

class UploadAssist extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      loading: false,
      posted: false,
      status: 'fail',
      current: 0,
      course: 'example',
      category: 1,
      categories: [],
      title: '',
      description: '',
      imgUrl: '',
      videoUrl: ''
    };
  }

  componentDidMount() {
    axios.get(`${apiHost}/api/v1/categories/`)
      .then(res => {
        let temp = [];
        res.data.forEach((category) => {
          if(category.parent_category !== null){
            temp.push(category)
          }
        });
        this.setState({
          categories: temp
        });
    })
  }

  next() {
    const current = this.state.current + 1;
    this.setState({ current });
  }

  prev() {
    const current = this.state.current - 1;
    this.setState({ current });
  }

  post() {

    this.setState({
      sending: !this.state.sending
    });

    const openNotification = (type, msg, des) => {
      notification[type]({
        message: msg,
        description: des
      });
    };

    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.headers = {
      "Authorization": 'Token '+ this.props.token,
      "X-CSRFTOKEN": cookie.load("csrftoken")
    }

    const fd = new FormData();

    if(!this.state.title || !this.state.description) {
      this.setState({posted: true, status: 'empty'});
    }

    fd.append("title", this.state.title);
    fd.append("description", this.state.description);
    fd.append("category", this.state.category);
    fd.append("thumbnail", this.state.imgUrl);
    fd.append("video", this.state.videoUrl);

    if(this.state.course === "core") {
      fd.append("infographics", '');
      fd.append("audio", '');
    };

    return axios.post(`${apiHost}/api/v1/${this.state.course}/create/`,fd)
    .then(res => {
      if(res.status === 201) {
        this.setState({status: 'success', posted: true, sending: !this.state.sending});
        openNotification('success', 'Hecho ',' El curso se ha registrado con éxito.');
      };
    })
    .catch(err => {
      openNotification('error', 'Error de envío ',' Datos publicados inválidos o faltan datos obligatorios ');
      this.setState({status: "error", sending: !this.state.sending});
    });


  }

  thumbnailUpload = (info) => {
     if (info.file.status === 'uploading') {
       this.setState({ loading: true });
       return;
     }
     if (info.file.status === 'done') {
       // Get this url from response in real world.
       getBase64(info.file.originFileObj, imgUrl => {
         this.setState({
           imgUrl,
           loading: false,
         });
       });
     }
  };

  videoUpload = info => {

    if (info.fileList.length > 1 ){
      info.fileList.shift();
    }

    this.setState({videoUrl: info.fileList[0].originFileObj});

  };

  render() {

    const { current, imgUrl, posted, status } = this.state;

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Subir</div>
      </div>
    );

    const categories = [];
    this.state.categories.forEach((category) => {
        categories.push(<Option key={category.id}>{category.category}</Option>);
    });

    const steps = [
      {
        title: 'Categoría',
        content:
        <MediaQuery minDeviceWidth={768}>
          {(matches) =>
          matches
            ? <div style={{textAlign:"left", marginLeft: 20}}>

              <div>
              <Text strong>¿Qué tipo de curso prefieres publicar?</Text><br />
              <Select
                showSearch
                style={{ width: "97%", marginTop: 20 }}
                placeholder="Selecciona el tipo de contenido"
                optionFilterProp="children"
                onChange={(value)  => {
                  this.setState({course : value});
                }}
                onSearch={(value) => {
                  this.setState({course : value});
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="core">Teórico</Option>
                <Option value="example">Práctico</Option>

              </Select>
              </div>

              <div style={{ marginTop: 40}}>
              <Text strong>¿Alguna categoría específica? </Text> <br />
              <Select
                showSearch
                style={{ width: "97%", marginTop: 20 }}
                placeholder="Selecciona la categoría para tu contenido"
                optionFilterProp="children"
                onChange={(value) => {
                  this.setState({category : value});
                }}
                onSearch={(value) => {
                  this.setState({category : value});
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {categories}

              </Select>
              </div>

            </div>

            : <div style={{textAlign:"left", marginLeft: 20}}>

              <div>
              <Text strong>¿Qué tipo de curso prefieres publicar?</Text><br />
              <Select
                showSearch
                style={{ width: "90%", marginTop: 20 }}
                placeholder="Selecciona el tipo de contenido"
                optionFilterProp="children"
                onChange={(value)  => {
                  this.setState({course : value});
                }}
                onSearch={(value) => {
                  this.setState({course : value});
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="core">Teórico</Option>
                <Option value="example">Práctico</Option>

              </Select>
              </div>

              <div style={{ marginTop: 40}}>
              <Text strong>¿Alguna categoría específica? </Text> <br />
              <Select
                showSearch
                style={{ width: "90%", marginTop: 20 }}
                placeholder="Selecciona la categoría para tu contenido"
                optionFilterProp="children"
                onChange={(value) => {
                  this.setState({category : value});
                }}
                onSearch={(value) => {
                  this.setState({category : value});
                }}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {categories}

              </Select>
              </div>

            </div>
          }
          </MediaQuery>

        ,
      },
      {
        title: 'Encabezados',
        content:
        <MediaQuery minDeviceWidth={768}>
          {(matches) =>
          matches
            ? <div style={{textAlign:"left", marginLeft: 20}}>

              <div>
              <Text strong>¿Cuál preferirías que sea el título del curso?</Text><br />
              <Input
                style={{ width: "97%", marginTop: 20 }}
                onChange={e => {
                  e.persist();
                  this.setState({title: e.target.value});
                }}
                placeholder="Dale un título interesante a tu curso. Límite de palabras: 40" />
              </div>

              <div style={{ marginTop: 40, marginBottom: 35}}>
              <Text strong>Describe brevemente los contenidos del curso. </Text> <br />
              <TextArea
                style={{ width: "97%", marginTop: 20 }}
                onChange={e => {
                  e.persist();
                  this.setState({description: e.target.value});
                }}
                placeholder="Escribe una descripción sobre tu curso" rows={4} />
              </div>

            </div>
            : <div style={{textAlign:"left", marginLeft: 20}}>

              <div>
              <Text strong>¿Cuál preferirías que sea el título del curso? </Text><br />
              <Input
                style={{ width: "90%", marginTop: 20 }}
                onChange={e => {
                  e.persist();
                  this.setState({title: e.target.value});
                }}
                placeholder="Dale un título interesante a tu curso. Límite de palabras: 40" />
              </div>

              <div style={{ marginTop: 40, marginBottom: 35}}>
              <Text strong>Describe brevemente los contenidos del curso. </Text> <br />
              <TextArea
                style={{ width: "90%", marginTop: 20 }}
                onChange={e => {
                  e.persist();
                  this.setState({description: e.target.value});
                }}
                placeholder="Escribe una descripción sobre tu curso" rows={4} />
              </div>

            </div>
          }
        </MediaQuery>
        ,
      },
      {
        title: 'Gráficos',
        content:
        <div style={{textAlign:"left"}}>
          <div className="thumbnail-uploader">
          <Text strong> Subir imagen del curso </Text>
          <Upload
            name="avatar"
            listType="picture-card"
            showUploadList={false}
            style={{width: 770, padding: 10, margin: "auto"}}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={this.thumbnailUpload}
          >
            {imgUrl ? <img src={imgUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
          </Upload>
          <Text secondary> <b>*</b>El tipo admitido incluye <i> jpeg </i> y <i> png </i>. Límite máximo de carga <i> 2MB </i></Text><br />
          </div>
        </div>,
      },
      {
        title: 'Vídeos Tutorial',
        content:
        <div>
        <Text style={{padding:10 }} strong> Arrastra el archivo en el cuadro o haz clic a continuación para subir el archivo de vídeo del curso. </Text><br/>

        <div style={{textAlign:"center", padding: 30}}>

          <Dragger onChange={this.videoUpload}>
            <p className="ant-upload-drag-icon">
              <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">Haz clic o arrastra el archivo a esta área para cargar</p><br />
            <p className="ant-upload-hint">
              Comprima el tamaño del archivo de video antes de subirlo. <br />
              Tenga en cuenta que las extensiones válidas incluyen <b> .mp4, .ogv </b> y <b> .webm </b>. Límite de carga <b> 30 MB </b>
            </p>
          </Dragger>
        </div>
        </div>,
      }
    ];



    if(!loggedUser) {
      return(
        <Result
        status="403"
        title="Se requiere iniciar sesión para cargar un contenido"
        subTitle="Debes iniciar sesión o registrarse para cargar el contenido."
        extra={[
          <Button href = '/login/' > Inicia sesión en Panzofi </Button>,
          <Button href = '/signup/'> Regístrate en Panzofi </Button>
        ]}
      />
      );
    }

    if(posted) {
      if(status === "success") {
        return (
          <Result
            status="success"
            title="Contenido publicado con éxito"
            subTitle="Felicidades ... ¡Su experiencia se ha incrementado!"
            extra={[
              <Button type="primary" href={`/${this.state.course}/`}>
                Actualizar contenido
              </Button>,
              <Button href="/"> Ir al inicio </Button>,
            ]}
          />
        );
      };
      if(status === "empty") {
        return (
          <Result
            status="warning"
            title="No hay datos enviados. ¡El título y la descripción son campos obligatorios!"
            extra={
              <Button type="primary" onClick={() => this.setState({posted: false})}>
                Inténtalo de nuevo
              </Button>
            }
          />
        );
      };
      if(status === "fail") {
      return(
        <div>Error: error de red</div>
      );
     };
    }

    return (
      <div>
      <Title level={4} style={{textAlign: "center"}}> Asistente de carga de contenido </Title>
      <Row style={{ marginTop: 50}}>
        {
          this.state.sending ?
          <div className="loader">
            <Spin tip="¡Buena publicación! Espera mientras validamos tu contenido en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
          </div>
          :
          <Col span={18} offset={3}>
            <MediaQuery minDeviceWidth={1024}>
              {(matches) =>
              matches
                ? <Steps current={current} type="navigation" size="small">
                  {steps.map(item => (
                    <Step key={item.title} title={item.title}/>
                  ))}
                 </Steps>
                : <MediaQuery minDeviceWidth={768}>
                  {(matches) =>
                  matches
                    ? <Steps current={current} progressDot size="small">
                      {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                      ))}
                    </Steps>
                    : <Steps current={current} progressDot direction="vertical" size="small">
                      {steps.map(item => (
                        <Step key={item.title} title={item.title}/>
                      ))}
                    </Steps>
                  }
                  </MediaQuery>


              }
            </MediaQuery>
            <div className="steps-content">{steps[current].content}</div>
            <div className="steps-action">
              {current < steps.length - 1 && (
                <Button type="primary" onClick={() => this.next()}>
                  Siguiente
                </Button>
              )}
              {current === steps.length - 1 && (
                <Button type="primary" onClick={() => this.post()}>
                  Hecho
                </Button>
              )}
              {current > 0 && (
                <Button style={{ marginLeft: 8 }} onClick={() => this.prev()}>
                  Anterior
                </Button>
              )}
            </div>

          </Col>
        }
      </Row>

      </div>
    );
  }
}

const WrappedUploadAssistLayout = Form.create({ name: 'upload-assist' })(UploadAssist);

const mapStateToProps = state => {
  return {
    token: state.token
  }
}

export default connect(mapStateToProps)(WrappedUploadAssistLayout);
