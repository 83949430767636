import React from 'react';

import Essentials from './Section/Essentials';
import Infographics from './Section/Infographics';
import Video from './Section/Video';
import Podcast from './Section/Podcast';
import TextArticle from './Section/TextArticle';
import Chronology from './Section/Chronology';


import { Col, Row, Tabs } from 'antd';

const { TabPane } = Tabs;

class CoreUpdate extends React.Component {

  render() {
    return (
      <div style={{padding: 80}}>
        <Row>
          <Col span={21} offset={3}>
          <Tabs tabPosition="left">

            <TabPane tab="Esenciales" key="1">
              <Essentials {...this.props} />
            </TabPane>

            <TabPane tab="Imágenes y gráficos" key="2">
              <Infographics {...this.props} />
            </TabPane>

            <TabPane tab="Vídeo del curso" key="3">
              <Video {...this.props} />
            </TabPane>

            <TabPane tab="Podcast" key="4">
              <Podcast {...this.props} />
            </TabPane>

            <TabPane tab="Texto del artículo" key="5">
              <TextArticle {...this.props} />
            </TabPane>

            <TabPane tab="Cronología" key="6">
              <Chronology {...this.props} />
            </TabPane>

          </Tabs>
          </Col>
        </Row>
      </div>
    );
  };
}


export default CoreUpdate;
