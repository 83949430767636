import React from 'react';
export const Banner51DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 k8nrmav3wr9-editor_css' },
  page: { className: 'home-page banner5-page k8nri7uui88-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <p>Siempre estamos felices de hablar</p>
              </span>
            </span>
          </span>
        ),
        className: 'banner5-title k8nri4hwzb-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain k8nrjcnekke-editor_css',
        children: (
          <span>
            <p>
              Visite una de nuestras oficinas para conversar o envíenos un
              mensaje utilizando el formulario a continuación.{' '}
            </p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://forms.gle/pqkTb31X56JrqeNt8',
          className: 'banner5-button k8nrjiwf0f-editor_css',
          type: 'primary',
          children: (
            <span>
              <p>Formulario</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: 'https://s3.amazonaws.com/panzofi.com/Banner-contacto.svg',
  },
};
export const Content110DataSource = {
  OverPack: {
    className: 'home-page-wrapper content11-wrapper k8nq24a4wat-editor_css',
    playScale: 0.3,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Consultas adicionales</p>
          </span>
        ),
        className: 'title-h1 k8nrlgsdklo-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <p>Envíe un correo electrónico a info@panzofi.com.</p>
          </span>
        ),
        className: 'title-content',
      },
      {
        name: 'content2',
        children: (
          <span>
            <span>
              <span>
                <p>HQ - Bogotá, Colombia</p>
                <p>
                  Horario de apertura&nbsp;<span>(GMT-5)</span>
                </p>
                <p>Lunes Viernes</p>
                <p>09:00 AM - 06:00 PM</p>
              </span>
            </span>
          </span>
        ),
        className: 'title-content k8nrlzgqz69-editor_css',
      },
    ],
  },
  button: {
    className: 'k8nrds5dvsr-editor_css',
    children: {
      a: {
        className: 'button k8nr78i5ld-editor_css',
        href: '#',
        children: (
          <span>
            <span>
              <span>
                <p>Clic para ir al formulario</p>
              </span>
            </span>
          </span>
        ),
      },
    },
  },
};
export const Contact00DataSource = {
  wrapper: {
    className: 'home-page-wrapper content10-wrapper k8nqizgknmi-editor_css',
  },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children: 'https://s3.amazonaws.com/panzofi.com/Ubicacion-contacto.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: {
        children: 'https://goo.gl/maps/m4M3m9tH6aqLyPSE8',
        name: '跳转地址',
      },
      title: { children: 'Dirección oficinas', name: '弹框标题' },
      content: { children: 'Bogotá, Colombia', name: '弹框内容' },
    },
  },
};
