import React from 'react';

import { connect } from 'react-redux';
import { NavLink, Redirect } from 'react-router-dom';

import { Button, Form, Icon, Input, Result, Spin } from 'antd';

import './Authentication.css'

import * as actions from '../../store/actions/auth';

const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class LoginForm extends React.Component {

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onAuth(
          values.username,
          values.password
        );
      }
    });
  }

  _refreshPage = () => {
    window.location.reload();
  }

  render() {

    const { getFieldDecorator } = this.props.form;

    if(localStorage.getItem('token')) {
      return <Redirect to='/' />
    }
    else if (this.props.error) {
     return  (
       <Result
        status="500"
        title="Fallo en la verificación"
        subTitle="Algo está mal con las credenciales que enviaste. ¡El sistema no pudo verificar tu identidad!"
        extra={[
          < Button onClick = {this._refreshPage} > Intenta iniciar sesión de nuevo </Button>,
          < Button href = '/signup/'> Regístrate en Panzofi </Button>
        ]}
       />
      );
    }
    else {
      return (
        <div>
          <br />
          {
              this.props.loading ?
              <div className="loader">
                <Spin tip="Verificando tus credenciales para Panzofi. Por favor se paciente." indicator={spinIcon} />
              </div>
              :
              <Form onSubmit={this.handleSubmit} style={{ width:300, margin: "0 auto"}} className="login-form">

                <Form.Item>
                  {getFieldDecorator('username', {
                    rules: [{ required: true, message: 'Por favor ingresa tu nombre de usuario' }],
                  })(
                    <Input
                      prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      placeholder="Nombre de usuario"
                    />,
                  )}
                </Form.Item>

                <Form.Item>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: 'Por favor ingresa tu contraseña' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                      type="password"
                      placeholder="Contraseña"
                    />,
                  )}
                </Form.Item>

                <Form.Item>
                  <Button type="primary" htmlType="submit" style={{ width:120, marginRight: "0 auto" }}>
                  Iniciar sesión
                  </Button> <br />
                  ¿No eres miembro todavía?
                  <NavLink style={{ width:150, margin: "0 auto"}} to='/signup/'>
                  <span> Regístrate</span>
                  </NavLink>
                </Form.Item>

              </Form>
          }
        </div>
      );
    }

  }
}

const WrappedLoginForm = Form.create({ name: 'login' })(LoginForm);

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (username, password) => dispatch(actions.authLogin(username, password))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedLoginForm);
