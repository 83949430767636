import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Button, Col, Form, Input, Row} from 'antd';

import './Authentication.css'
import * as actions from '../../store/actions/auth';

class RegistrationForm extends React.Component {

  state = {
    confirmDirty: false,
    autoCompleteResult: [],
  };

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onAuth(
          values.username,
          values.email,
          values.password,
          values.confirm
        );

      }

    });
  };

  handleConfirmBlur = e => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue('password')) {
      callback('¡Las dos contraseñas que ingresas son inconsistentes!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if(value && value.length < 8) {
      callback('¡La contraseña debe tener al menos 8 caracteres!');
    }
    if (value && this.state.confirmDirty) {
      form.validateFields(['confirm'], { force: true });
    }
    callback();
  };

  validateUsername = (rule, value, callback) => {
    const { form } = this.props;
    if (value && /\s/g.test(value)) {
      callback('¡El nombre de usuario no debe contener espacios en blanco!');
    }
    callback();
  };

  render() {

    const { getFieldDecorator, getFieldError, isFieldTouched } = this.props.form;
    const usernameError = isFieldTouched('username') && getFieldError('username');
    return (

      <Row>
      <Col span={12} offset={6}>
      <Form className="register-form" onSubmit={this.handleSubmit}>
        <Form.Item hasFeedback>
          {getFieldDecorator('username', {
            rules: [
              {
                required: true,
                message: 'Por favor ingresa tu nombre de usuario sin espacio',
                whitespace: false
              },
              {
                validator: this.validateUsername,
              },
            ],
          })(<Input placeholder="selecciona un nombre de usuario"/>)}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'La entrada no es válida',
              },
              {
                required: true,
                message: 'Por favor ingresa tu correo electrónico!',
              },
            ],
          })(<Input placeholder="ingresa tu dirección de correo electrónico"/>)}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator('password', {
            rules: [
              {
                required: true,
                message: 'Por favor ingresa tu contraseña!',
              },
              {
                validator: this.validateToNextPassword,
              },
            ],
          })(<Input.Password placeholder="ingresa una fuerte combinación de palabras clave"/>)}
        </Form.Item>

        <Form.Item hasFeedback>
          {getFieldDecorator('confirm', {
            rules: [
              {
                required: true,
                message: 'Por favor, confirma tu contraseña',
              },
              {
                validator: this.compareToFirstPassword,
              },
            ],
          })(<Input.Password placeholder="confirma la contraseña" onBlur={this.handleConfirmBlur} />)}
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Regístrate
          </Button> <br />
          ¿Ya eres usuario?
          <NavLink style={{ width:150, margin: "0 auto"}} to='/login/'>
          <span> Iniciar sesión</span>
          </NavLink>
        </Form.Item>

      </Form>
      </Col>
      </Row>


    );
  }
}

const WrappedRegistrationForm = Form.create({ name: 'register' })(RegistrationForm);

const mapStateToProps = (state) => {
  return {
    loading: state.loading,
    error: state.error
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (userName,email, password1, password2) => dispatch(actions.authSignup(userName, email, password1, password2))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WrappedRegistrationForm);
