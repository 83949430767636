import React from 'react';
import cookie from "react-cookies";
import { connect } from 'react-redux';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import MediaQuery from 'react-responsive';

import Detail from '../../components/Content/Example/Detail';
import DetailResponsive from '../../components/Content/Example/DetailResponsive';

import { Form, message, Modal, Typography } from 'antd';

const { Title } = Typography;
const { confirm } = Modal;

const loggedUser = localStorage.getItem('username');

const isEmpty = (obj) => {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
         return false;
   }
   return true;
}

class ExampleContentDetail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      slug : this.props.match.params.slug,
      example: {},
      fun: '',
      no_fun: '',
      useful: '',
      efficient: ''
    };
    this.showDeleteConfirm = this.showDeleteConfirm.bind(this);
  };

  componentDidMount() {
    axios.get(`${apiHost}/api/v1/example/${this.state.slug}/`)
      .then(res => {
        this.setState({
          example: res.data
        });
        this.setState({
          fun: this.state.example.fun,
          no_fun: this.state.example.no_fun,
          efficient: this.state.example.efficient,
          useful: this.state.example.useful,
        });
      })
  };

  showDeleteConfirm() {

    const deleteToken = this.props.token;
    const deleteSlug = this.state.slug;

    confirm({
      title: '¿Seguro que quieres eliminar este curso?',
      content: 'No se te permitirá revertir tus acciones',
      okText: 'Eliminar',
      okType: 'danger',
      cancelText: 'Cancelar',
      onOk(){

        axios.defaults.xsrfCookieName = "csrftoken";
        axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
        axios.defaults.headers = {
          "Authorization": `Token ${deleteToken}`,
          "X-CSRFTOKEN": cookie.load("csrftoken")
        }

        axios.delete(`${apiHost}/api/v1/example/${deleteSlug}/delete/`)
        .then((res) => {
          if(res.status === 204){
            console.log('deleted');
            message.info('¡El curso ha sido eliminado con éxito!',5);
          }
        })
        .catch(err => console.error(err));
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  }

  request = (reaction) => {
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.withCredentials = true
    axios.defaults.headers = {
      "Authorization": 'Token '+ this.props.token,
      "X-CSRFTOKEN": cookie.load("csrftoken")
    }
    if(!loggedUser){
      message.info('¡Se requiere iniciar sesión para esta acción!',5);
      return;
    }
    return axios.get(`${apiHost}/api/v1/example/${this.state.slug}/${reaction}/`)
    .then(res => {
      axios.get(`${apiHost}/api/v1/example/${this.state.slug}/`)
      .then( res => {
        console.log(res);
        this.setState({
          fun : res.data.fun,
          no_fun: res.data.no_fun,
          efficient: res.data.efficient,
          useful: res.data.useful,
        });
      })

    })
    .catch(error => {
      message.error('Parece que estás utilizando un navegador desactualizado ... ¡o podría ser un error de red!',5);
    });
  };

  funHandler = () => {
    this.request('fun');
  };

  nofunHandler = () => {
    this.request('no_fun');
  };

  efficientHandler = () => {
    this.request('efficient');
  };

  usefulHandler = () => {
    this.request('useful');
  };

  render() {
    if(!isEmpty(this.state.example)){
      return (
        <MediaQuery minDeviceWidth={1024}>
          {(matches) =>
          matches
            ? <Detail {...this.state}
              funHandler={this.funHandler} nofunHandler={this.nofunHandler} usefulHandler={this.usefulHandler} efficientHandler={this.efficientHandler}
              showDeleteConfirm={this.showDeleteConfirm}
              loggedUser={loggedUser}
            />
            : <DetailResponsive {...this.state}
              funHandler={this.funHandler} nofunHandler={this.nofunHandler} usefulHandler={this.usefulHandler} efficientHandler={this.efficientHandler}
              showDeleteConfirm={this.showDeleteConfirm}
              loggedUser={loggedUser}
            />
          }
        </MediaQuery>

      );
    } else {
      return(<Title level={4}>cargando ... por favor espera </Title>);
    };

  };
}

const WrappedDetailExampleLayout = Form.create({ name: 'example-detail' })(ExampleContentDetail);

const mapStateToProps = state => {
  return {
    token: state.token
  }
}

export default connect(mapStateToProps)(WrappedDetailExampleLayout);
