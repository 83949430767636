import React from 'react';

import { List, Avatar, Icon, Typography } from 'antd';

const { Paragraph } = Typography;

class ExampleContent extends React.Component {

  render() {
    let src = "";
    return(
        <List
          itemLayout="vertical"
          size="default"
          pagination={{
            onChange: page => {
              console.log(page);
            },
            pageSize: 10,
          }}
          dataSource={this.props.data}
          renderItem={item => (
            <List.Item
              key={item.title}
              actions={[
                <span><Icon
                  style={{ marginRight: 8, paddingLeft: 30 }}
                  type="edit" theme="twoTone" twoToneColor="#f67177" key="list-vertical-edit" /> Autor: {item.author}
                </span>,

                <span><Icon
                  style={{ marginRight: 8 }}
                   type="calendar" theme="twoTone" twoToneColor="#f67177" key="list-vertical-calendar" /> Publicado en: {item.publish}
                </span>,

                <span><Icon
                  style={{ marginRight: 8 }}
                  type="eye" theme="twoTone" twoToneColor="#f67177" key="list-vertical-eye" />Vistas totales: {item.total_views}
                </span>,

              ]}
              extra={
                <img
                  width={272}
                  height={145}
                  alt = "" //"Doofus no thumbnail here!"
                  src={item.thumbnail}
                />
              }
            >
              <List.Item.Meta
                avatar={
                  <Avatar
                    src={typeof item.author_avatar[0] === 'undefined' ?
                                src="https://s3.amazonaws.com/panzofi.com/Avatar.png"
                              : src=`${item.author_avatar[0]["profile_picture"]}`}

                 />
                }
                title={<a href={`/example/${item.slug}`}>{item.title}</a>}
                description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
              />

            </List.Item>
          )}
        />
    );
  };

}

export default ExampleContent;

/*
Use the following url for displaying user avatars
src={`http://localhost:8000${item.author_avatar[0]["profile_picture"]}`}
*/
