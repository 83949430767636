import React from 'react';

import { Link } from 'react-router-dom';

import './Content.css';
import { client } from '../../config.dev.js';

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton, WhatsappShareButton, PinterestShareButton, FacebookIcon, LinkedinIcon, TwitterIcon, WhatsappIcon, PinterestIcon } from 'react-share';
import { Avatar, Button, Card, Col, Divider, Icon, List, Modal, Popover, Row, Tooltip, Typography } from 'antd';

const { Paragraph, Title } = Typography;
const { Meta } = Card;

class HomeContent extends React.Component {

  state = { visible: false, link: '', media: '' };

  showModal = (e, link, media) => {
    e.preventDefault();
    this.setState({
      visible: true,
      link,
      media
    });
  };

  handleOk = e => {
    console.log(e);
    this.setState({
      visible: false,
      link: '',
      media:''
    });
  };

  handleCancel = e => {
    console.log(e);
    this.setState({
      visible: false,
      link: '',
      media: ''
    });
  };

  render() {
    let src = "";
    const share = (link, media) => {
      const copy = `${client}${link}`;
      return(
        <div>
          <p>Aquí hay un enlace para compartir<Paragraph copyable>{copy}</Paragraph></p>
          <p>¿Compartir en las redes sociales? <Button type="link" onClick={(e) => this.showModal(e, link, media)}>Haz clic</Button></p>
        </div>
      );

    };

    return(
      <React.Fragment>

      <Modal
          title="Comparte en tu plataforma social favorita"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
          <Row type="flex" justify="start" align="middle">
            <Col span={3} offset={5}>
            <FacebookShareButton
              url={`${client}${this.state.link}`}
              quote="Conoce más @panzofi.com" >
              <FacebookIcon
                size={32}
                round />
            </FacebookShareButton>
            </Col>
            <Col span={3}>
            <PinterestShareButton
              url={`${client}${this.state.link}`}
              media={`${this.state.media}`}
              quote="Conoce más @panzofi.com" >
              <PinterestIcon
                size={32}
                round />
            </PinterestShareButton>
            </Col>
            <Col span={3}>
            <TwitterShareButton
              url={`${client}${this.state.link}`}
              quote="Conoce más @panzofi.com" >
              <TwitterIcon
                size={32}
                round />
            </TwitterShareButton>
            </Col>
            <Col span={3}>
            <WhatsappShareButton
              url={`${client}${this.state.link}`}
              quote="Conoce más @panzofi.com" >
              <WhatsappIcon
                size={32}
                round />
            </WhatsappShareButton>
            </Col>
            <Col span={3}>
            <LinkedinShareButton
              url={`${client}${this.state.link}`}
              quote="Conoce más @panzofi.com" >
              <LinkedinIcon
                size={32}
                round />
            </LinkedinShareButton>


            </Col>
          </Row>
      </Modal>

      <Divider> <Title level={3}> Cursos teóricos recomendados </Title> </Divider>
      <List
        grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 6, }}
        dataSource={this.props.core.sort(() => 0.5 - Math.random()).slice(0, 8)}
        style={{ marginLeft: 80, marginTop: 15}}
        renderItem={item => (
          <List.Item>
            <Link to={`/core/${item.slug}/`}>
            <Card
               hoverable
               style={{ width: 240 }}
               cover={<img alt="" src={item.thumbnail} style={{ height:120, maxHeight: 120 }}/>}
               actions={[
                 <Popover content={share(`/core/${item.slug}`,`${item.thumbnail}`)} title="Compartir"><Icon type="share-alt" key="share" /></Popover>,
                 <Tooltip title="Más info"><a href={`/core/${item.slug}`}><Icon type="ellipsis" key="ellipsis" /></a></Tooltip>,
               ]}
            >
               <Tooltip title={`${item.title} by ${item.author}`} placement="bottom">
               <Meta
                 avatar={<Avatar size={50} style={{ marginTop: 8 }}
                   src={typeof item.author_avatar[0] === 'undefined' ?
                               src="https://s3.amazonaws.com/panzofi.com/Avatar.png"
                             : src=`${item.author_avatar[0]["profile_picture"]}`}

                  />}
                 title={<span style={{fontSize: 14}}>{item.title}</span>}
                 description={<span style={{marginTop: 1}}>{item.author}</span>}
               />
              </Tooltip>
            </Card>
            </Link>

        </List.Item>
      )}
    />

      <Row type="flex" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
        <Col>
          <Button style={{ marginTop: 10, marginBottom: 10 }} type="primary" href="/core" shape="round" icon="play-circle" size="large">
             Explorar más
          </Button>
        </Col>
      </Row>

      <Divider> <Title level={3}> Cursos prácticos recomendados </Title> </Divider>

      <List
        grid={{ gutter: 8, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 6, }}
        dataSource={this.props.example.sort(() => 0.5 - Math.random()).slice(0, 8)}
        style={{ marginLeft: 80, marginTop: 15}}
        renderItem={item => (
          <List.Item>
          <Link to={`/example/${item.slug}/`}>
            <Card
               hoverable
               style={{ width: 240 }}
               cover={<img alt="" src={item.thumbnail} style={{ height:120, maxHeight: 120 }}/>}
               actions={[
                 <Popover content={share(`/example/${item.slug}`,`${item.thumbnail}`)} title="Compartir"><Icon type="share-alt" key="share" /></Popover>,
                 <Tooltip title="Más info"><a href={`/example/${item.slug}`}><Icon type="ellipsis" key="ellipsis" /></a></Tooltip>,
               ]}
            >
               <Tooltip title={`${item.title} by ${item.author}`} placement="bottom">
               <Meta
                 avatar={<Avatar size={50} style={{ marginTop: 8 }}
                   src={typeof item.author_avatar[0] === 'undefined' ?
                               src="https://s3.amazonaws.com/panzofi.com/Avatar.png"
                             : src=`${item.author_avatar[0]["profile_picture"]}`}

                  />}
                 title={<span style={{fontSize: 14}}>{item.title}</span>}
                 description={<span style={{marginTop: 1}}>{item.author}</span>}
               />
              </Tooltip>
              </Card>
            </Link>

        </List.Item>
      )}
    />

      <Row type="flex" justify="center" style={{ marginTop: 10, marginBottom: 10 }}>
        <Col>
          <Button style={{ marginTop: 10, marginBottom: 10 }} type="primary" href="/example" shape="round" icon="play-circle" size="large">
             Explorar más
          </Button>
        </Col>
      </Row>


      </React.Fragment>
  );
  }
}


export default HomeContent;
