import React from 'react';

import MediaQuery from 'react-responsive';

import { Button, Col, Form, Icon, Row, Select, Spin, Typography } from 'antd';

const { Option } = Select;
const { Text } = Typography;

const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class Essentials extends React.Component {

  state = {
    previous:'',
    next:''
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    let updateContent = {
      category:'default',
      title:'default',
      description:'default',
      thumbnail:'default',
      infographics:'default',
      video:'default',
      audio:'default',
      textArticle: 'default',
      previous: this.state.previous,
      next: this.state.next,

    }
    this.props.updateContent(updateContent);
  }

  render() {

    console.log(this.props);
    const previous = [];
    const next = [];

     this.props.core.forEach((core) => {
         previous.push(<Option key={core.id}>{core.title}</Option>);
         next.push(<Option key={core.id}>{core.title}</Option>);
     });

    return(
      <React.Fragment>
      {
        this.props.sending?
        <div className="loader">
          <Spin tip="¡Gran actualización! Espera mientras validamos tu contenido en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
        </div>
       :
        <Form onSubmit={(event) => this.handleFormSubmit(event)}>

        <Text strong>¿Existe un curso teórico anterior relacionado a este?</Text><br />
        <Form.Item style={{marginTop: 20}}>
          <Select
            showSearch
            placeholder="selecciona una cronología previa para tu contenido"
            optionFilterProp="children"
            onChange={(value) => {
              this.setState({previous : value});
            }}
            onSearch={(value) => {
              this.setState({previous : value});
            }}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {previous}

          </Select>
        </Form.Item>


        <Text strong>¿Hay un próximo curso teórico relacionado a este? </Text><br />
        <Form.Item style={{marginTop: 20}}>
          <Select
            showSearch
            placeholder="selecciona una próxima cronología para tu contenido"
            optionFilterProp="children"
            onChange={(value) => {
              this.setState({next : value});
            }}
            onSearch={(value) => {
              this.setState({next : value});
            }}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {next}

          </Select>
        </Form.Item>


        <Form.Item>
        <MediaQuery minDeviceWidth={768}>
          {(matches) =>
          matches
            ? <Row>
              <Col span={5}>
                <Button type="primary" htmlType="submit" >Actualización del curso</Button>
              </Col>
              <Col span={4} offset={1}>
                <Button type="default" href={`/core/${this.props.init_content.slug}`} >Ver curso</Button>
              </Col>
            </Row>
            : <div>
              <Row>
                <Col>
                  <Button type="primary" htmlType="submit" block>Actualización del curso</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="default" href={`/core/${this.props.init_content.slug}`} block>Ver curso</Button>
                </Col>
              </Row>
            </div>
          }
        </MediaQuery>
        </Form.Item>
        </Form>
      }
      </React.Fragment>
    );
  }
}

export default Essentials;
