import * as actions from './actions';
import axios from 'axios';

import { notification, Icon } from 'antd';

import { apiHost, client } from '../../config.dev.js';

const openNotification = (type, msg, des) => {
  notification[type]({
    duration: 0,
    message: msg,
    description: des
  });
};

export const authStart = () => {
  return {
    type: actions.AUTH_START
  }
}

export const authSuccess = token => {

  return {
    type: actions.AUTH_SUCCESS,
    token: token
  }
}

export const authFail = error => {
  openNotification('error', 'Registration Failed!', error.response.request.response);
  return {
    type: actions.AUTH_FAIL,
    error: error
  }
}


export const logout = () => {
  if (localStorage.getItem('token') !== null) {
    window.location.href = `${client}`;
  };

  localStorage.removeItem('username');
  localStorage.removeItem('token');
  localStorage.removeItem('expirationDate');
  localStorage.removeItem('categories');


  return {
    type: actions.AUTH_LOGOUT
  }
}

export const checkAuthTimeout = expirationTime => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout());
    }, expirationTime * 1000)
  }
}

export const authLogin = (username, password) => {
  return dispatch => {
    dispatch(authStart());
    axios.post(`${apiHost}/api/v1/authentication/login/`, {
      username: username,
      password: password
    })
    .then(res => {
      const token = res.data.key;
      const expirationDate = new Date(new Date().getTime() + 3600 * 1000 * 24);
      localStorage.setItem('username', username);
      localStorage.setItem('token', token);
      localStorage.setItem('expirationDate', expirationDate);
      dispatch(authSuccess(token));
      dispatch(checkAuthTimeout(86400));
    })
    .catch(err => {
      dispatch(authFail(err))

    })
  }
}

export const authSignup = (username, email, password1, password2) => {
  return dispatch => {
    dispatch(authStart());
    axios.post(`${apiHost}/api/v1/authentication/registration/`, {
      username: username,
      email: email,
      password1: password1,
      password2: password2
    })
    .then(res => {
      const token = res.data.key;
      const expirationDate = new Date(new Date().getTime() + 3600 * 1000 * 24);
      localStorage.setItem('username', username);
      localStorage.setItem('token', token);
      localStorage.setItem('expirationDate', expirationDate);
      if (res.status === 201) {
        openNotification('success', 'Successfully registrated!', 'Congratulations, you have successfully registered to Panzofi.');
        window.location.href = `${client}`;
      }
      dispatch(authSuccess(token));
      dispatch(checkAuthTimeout(86400));
    })
    .catch(err => {
      dispatch(authFail(err))
    })
  }
}

export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (token === undefined) {
        dispatch(logout());
    } else {
      const expirationDate = new Date(localStorage.getItem('expirationDate'));
      if (expirationDate <= new Date()) {
        dispatch(logout());
      } else {
        dispatch(authSuccess(token));
        dispatch(checkAuthTimeout( (expirationDate.getTime() - new Date().getTime()) / 1000));

      }
    }
  }
}
