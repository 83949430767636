import React from 'react';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import SearchResults from '../../components/Search/Search';
import './SearchContent.css';

class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = this.initialState;
    this.cancel = '';
  }

  get initialState() {
    return {
      minFun: '',
      minEfficient: '',
      minUseful: '',
      publish: '',
      publishAfter: '',
      publishBefore: '',
      author: '',
      core: [],
      example: [],
      message: '',
      clear: ''
    };
  }

  get resetFilter() {
    return {
      minFun: '',
      minEfficient: '',
      minUseful: '',
      author: '',
      publish: '',
      publishAfter: '',
      publishBefore: '',
    };
  }

  updateFunFilter = (value) => {
      this.setState({
        minFun: value
      });
    };
  updateUsefulFilter = (value) => {
      this.setState({
        minUseful: value
      });
    };
  updateEfficientFilter = (value) => {
      this.setState({
        minEfficient: value
      });
    }

  updateAuthorFilter = (value) => {
      this.setState({
        author: value
      });
  };

  updatePublishFilter = (value) => {
    this.setState({
      publish: value
    });
  };
  updatePublishAfterFilter = (value) => {
    this.setState({
      publishAfter: value
    });
  };
  updatePublishBeforeFilter = (value) => {
    this.setState({
      publishBefore: value
    });
  };

  reset = () => {
    this.setState(this.resetFilter);
  };

  executeSearch(){

    const base = `${apiHost}/api/v1/`;
    const query = `?search=${this.props.location.keyword}`;
    const ratings = `&fun_min_filter=${this.state.minFun}&useful_min_filter=${this.state.minUseful}&efficient_min_filter=${this.state.minEfficient}`;
    const publish = `&publish_filter=${this.state.publish}&publish_range_filter_after=${this.state.publishAfter}&publish_range_filter_before=${this.state.publishBefore}`;
    const author = `&author_filter=${this.state.author}`;

    const searchCoreUrl = `${base}core/${query}${ratings}${publish}${author}`;
    const searchExampleUrl =  `${base}example/${query}${ratings}${publish}${author}`;

    if( this.cancel ) {
      this.cancel.cancel();
    }

    this.cancel = axios.CancelToken.source();

    axios.all([
      axios.get(searchCoreUrl, {
        CancelToken: this.cancel.token
      }),
      axios.get(searchExampleUrl, {
        CancelToken: this.cancel.token
      })
    ])
    .then(axios.spread((coreResults, exampleResults) => {
      const resultMsg =! coreResults.data.length || exampleResults.data.length
                              ? 'No se encontraron resultados de búsqueda. Intenta una nueva búsqueda.'
                              : '';

      this.setState({
        core: coreResults.data,
        example: exampleResults.data,
        message: resultMsg
      })


    }))
    .catch( error => {
      if(axios.isCancel(error) || error) {
        this.setState({
          message: 'Error al recuperar los datos. Por favor verifique la red'
        })
      }
    });
  }

  componentDidMount(){
    this.executeSearch();
  }

  componentDidUpdate(prevProps, prevState){
    if (prevProps !== this.props ||
      prevState.minFun !== this.state.minFun ||
      prevState.minUseful !== this.state.minUseful ||
      prevState.minEfficient !== this.state.minEfficient ||
      prevState.author !== this.state.author ||
      prevState.publish !== this.state.publish ||
      prevState.publishAfter !== this.state.publishAfter ||
      prevState.publishBefore !== this.state.publishBefore) {
      this.executeSearch();
    }

    if (prevState.publishAfter !== this.state.publishAfter &&
      prevState.publishBefore !== this.state.publishBefore) {
      this.executeSearch();
    }

  }

  render(){
    return (
      <SearchResults
         {...this.state}
         query={this.props.location.keyword}  reset={this.reset}

         funFilterCallback={this.updateFunFilter}
         usefulFilterCallback={this.updateUsefulFilter}
         efficientFilterCallback={this.updateEfficientFilter}

         authorFilterCallback={this.updateAuthorFilter}

         publishFilterCallback={this.updatePublishFilter}
         publishAfterFilterCallback={this.updatePublishAfterFilter}
         publishBeforeFilterCallback={this.updatePublishBeforeFilter}

      />
    );
  }

}
export default Search;
