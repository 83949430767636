import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 k8kkfa5kw5f-editor_css' },
  page: { className: 'home-page banner5-page k8n5tn7fmaf-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Estamos cambiando la forma de aprender y enseñar en línea</p>
          </span>
        ),
        className: 'banner5-title k8kixnv25mt-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain k8kixshzczn-editor_css',
        children: (
          <span>
            <p>Educación de todos y para todos con Panzofi</p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://www.panzofi.com/core/como-funciona-panzofi',
          className: 'banner5-button k8kiz78lxjq-editor_css',
          type: 'primary',
          children: (
            <span>
              <p>Más información</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image k8kjb2t4uul-editor_css',
    children: 'https://s3.amazonaws.com/panzofi.com/Banner_ini.svg',
  },
};
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0 k8mdpikyqmq-editor_css' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Nuestros servicios</p>
          </span>
        ),
        className: 'k8kivt9qr9-editor_css',
      },
      {
        name: 'content~k8kivwvx2g',
        className: 'k8kiw6787rp-editor_css',
        children: (
          <span>
            <p>
              Panzofi es un red de aprendizaje enfocada en contenido educacional
              entretenido
            </p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://s3.amazonaws.com/panzofi.com/Estudiantes-ini.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title k8kj8ngoe4-editor_css',
              children: (
                <span>
                  <p>Estudiantes</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      Busca y encuentra en un par de clics contenido gratis.
                      Participa con la red para aprender mejor colaborando y
                      encuentra contenido personalizado
                    </p>
                  </span>
                </span>
              ),
              className: 'k8kj65ejfde-editor_css',
            },
            {
              name: 'button~k8kiwz28f2n',
              className: '',
              children: {
                children: (
                  <span>
                    <p>Más información</p>
                  </span>
                ),
                href: 'https://www.panzofi.com/core/como-funciona-panzofi/',
                type: 'default',
                className: 'k8kj1v8rd9g-editor_css',
              },
            },
          ],
        },
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://s3.amazonaws.com/panzofi.com/Profesores-ini.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title k8kj8rij3lq-editor_css',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>Docentes</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <p>
                      Comparte y colabora con otros colegas material para
                      identificar las mejores técnicas de enseñanza y el mejor
                      contenido para tus estudiantes
                    </p>
                  </span>
                </span>
              ),
              className: 'k8kj694w2ou-editor_css',
            },
            {
              name: 'button~k8kix23wow',
              className: '',
              children: {
                children: (
                  <span>
                    <p>Más información</p>
                  </span>
                ),
                href: 'https://www.panzofi.com/teachers/',
                type: 'default',
                className: 'k8kj2ohvl5a-editor_css',
              },
            },
          ],
        },
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children: 'https://s3.amazonaws.com/panzofi.com/Empresas-ini.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title k8kj8urs3m-editor_css',
              children: (
                <span>
                  <p>Empresas</p>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          Encuentra los mejores cursos para tus empleados y una
                          plataforma que permite que el aprendizaje sea más
                          rápido. El recurso más valioso es el capital humano
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
              className: 'k8kj6ceyn1-editor_css',
            },
            {
              name: 'button~k8kix3t2n9j',
              className: '',
              children: {
                children: (
                  <span>
                    <p>Más información</p>
                  </span>
                ),
                href: 'https://www.panzofi.com/business/',
                type: 'default',
                className: 'k8kj347ovoc-editor_css',
              },
            },
          ],
        },
      },
      {
        name: 'block~k8kirk887xe',
        className: 'content0-block',
        md: 6,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon',
              children:
                'https://s3.amazonaws.com/panzofi.com/InsEducacion-ini.svg',
            },
            {
              name: 'title',
              className: 'content0-block-title k8kj8xoxheh-editor_css',
              children: (
                <span>
                  <span>
                    <p>Instituciones educativas</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        Aumenta la tasa de terminación. Identifica que contenido
                        tiene más valor para tus estudiantes. Ahorra costos de
                        producción y tiempo de desarrollo
                      </p>
                    </span>
                  </span>
                </span>
              ),
              className: 'k8kj6gac4r6-editor_css',
            },
            {
              name: 'button~k8kix51a6bt',
              className: '',
              children: {
                children: (
                  <span>
                    <p>Más infomración</p>
                  </span>
                ),
                href: 'https://www.panzofi.com/institutionedu/',
                type: 'default',
                className: 'k8kj4x1fl8-editor_css',
              },
            },
          ],
        },
      },
    ],
  },
};
export const Feature80DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature8-wrapper k8kfjxc9lua-editor_css',
  },
  page: { className: 'home-page feature8 k8kem7xzolm-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1 k8kgln2n2yn-editor_css',
        children: (
          <span>
            <p>¿Cómo funciona?</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content k8kgoqb8k4-editor_css',
        children: (
          <span>
            <p>Es muy fácil e intuitivo sin importar la edad</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: 'https://www.panzofi.com/core/como-funciona-panzofi',
          children: (
            <span>
              <p>Más información</p>
            </span>
          ),
          className: 'k8kep9qwwuq-editor_css',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: (
              <span>
                <p>Sin cuenta</p>
              </span>
            ),
          },
          children: [
            {
              className: 'feature8-block-col k8kf26qzxz8-editor_css',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/cursor-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8kj97o7pcj-editor_css',
                    children: (
                      <span>
                        <p>1. Navegar</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8kekta8gcj-editor_css',
                    children: (
                      <span>
                        <p>
                          Ver el contenido totalmente gratis y sin restricciones
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/search-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8kj9c6ampl-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>2. Buscar</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8kelc3uly-editor_css',
                    children: (
                      <span>
                        <p>
                          Buscar el contenido de tu interés o un tema en
                          especifico
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/sid-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8kj9i01re9-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>3. Encontrar</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8kelngdad-editor_css',
                    children: (
                      <span>
                        <p>Encontrar recursos teóricos y prácticos en la red</p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/share-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8kj9lfwwo-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>4. Compartir</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8kelzi349-editor_css',
                    children: (
                      <span>
                        <p>
                          Compartir con tus familiares, amigos y compañeros de
                          estudio
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
        {
          name: 'block1',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            children: (
              <span>
                <p>Con cuenta</p>
              </span>
            ),
            className: 'feature8-carousel-title-block',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/like-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8kj9ushsjd-editor_css',
                    children: (
                      <span>
                        <p>5. Calificar</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8keog2553u-editor_css',
                    children: (
                      <span>
                        <p>
                          Calificar el contenido si es divertido, eficiente y
                          útil{' '}
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/upload-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8kja0bwxx-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>6. Subir</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8keonlx7z-editor_css',
                    children: (
                      <span>
                        <p>
                          Subir contenido teórico y práctico para que todos lo
                          vean
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/partici-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8kja3lfka-editor_css',
                    children: (
                      <span>
                        <p>7. Participar</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8keou2iohi-editor_css',
                    children: (
                      <span>
                        <p>
                          Participar y colaborar con otros usuarios para
                          aprender mejor
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/medal-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8kja6v7h7u-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>8. Ganar</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8kep04xoo-editor_css',
                    children: (
                      <span>
                        <p>
                          Ganar una reputación en la red y mejora el perfil
                          educativo{' '}
                        </p>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Content40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content4-wrapper k8kkmjajkii-editor_css',
  },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Sube contenido y participa ahora</p>
          </span>
        ),
        className: 'title-h1 k8kislxpw9-editor_css',
      },
      {
        name: 'content',
        className:
          'title-content content4-title-content k8kisvhc8zd-editor_css',
        children: (
          <span>
            <p>Se parte de nuestra revolución en el sector de la educación</p>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video:
        'https://s3.amazonaws.com/panzofi.com/Video_panzofi_30001-2559.mp4',
      image: 'https://s3.amazonaws.com/panzofi.com/video-ima-ini.svg',
    },
  },
};
