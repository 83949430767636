import React from 'react';
export const Banner50DataSource = {
  wrapper: { className: 'home-page-wrapper banner5 k8n9uqqqm2i-editor_css' },
  page: { className: 'home-page banner5-page' },
  childWrapper: {
    className: 'banner5-title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <p>Estamos cambiando la forma de enseñar en línea</p>
            </span>
          </span>
        ),
        className: 'banner5-title k8n8pw7muj-editor_css',
      },
      {
        name: 'explain',
        className: 'banner5-explain k8n8q898h77-editor_css',
        children: (
          <span>
            <span>
              <p>
                Sube cursos en línea e interactúa con estudiantes de todo el
                mundo
              </p>
            </span>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: 'https://www.panzofi.com/core/introduccion-panzofi-para-docentes/',
          className: 'banner5-button k8n8qidjazi-editor_css',
          type: 'primary',
          children: (
            <span>
              <p>Más información</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: 'banner5-image',
    children: 'https://s3.amazonaws.com/panzofi.com/Banner_docentes.svg',
  },
};
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://s3.amazonaws.com/panzofi.com/Docentes-imagen-1.svg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title k8na441tado-editor_css',
    children: (
      <span>
        <p>Los docentes del futuro</p>
      </span>
    ),
  },
  content: {
    className: 'content1-content k8na8mccj-editor_css',
    children: (
      <span>
        <p>
          Comparten y colaboran con otros para identificar las mejores técnicas
          de enseñanza y que contenido es el mejor para sus estudiantes
        </p>
      </span>
    ),
  },
};
export const Feature20DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://s3.amazonaws.com/panzofi.com/Docentes-imagen-2.svg',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title k8na57ckojq-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>Los docentes del mañana</p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content2-content k8na8rw9mmf-editor_css',
    children: (
      <span>
        <span>
          <p>
            Entienden la calificación de los estudiantes y generan una
            participación activa al diseñar nuevos escenarios formativos en la
            comunidad
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://s3.amazonaws.com/panzofi.com/Docentes-imagen-3.svg',
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title k8na5j1rs5s-editor_css',
    children: (
      <span>
        <span>
          <span>
            <p>Los docentes innovadores</p>
          </span>
        </span>
      </span>
    ),
  },
  content: {
    className: 'content1-content k8na8y0xbb8-editor_css',
    children: (
      <span>
        <span>
          <p>
            Emplean estratégicamente la información para disminuir costos de
            producción al colaborar con la red y dividir cargas de creación
            digital
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature21DataSource = {
  wrapper: { className: 'home-page-wrapper content2-wrapper' },
  OverPack: { className: 'home-page content2', playScale: 0.3 },
  imgWrapper: { className: 'content2-img', md: 10, xs: 24 },
  img: {
    children: 'https://s3.amazonaws.com/panzofi.com/Docentes-imagen-4.svg',
  },
  textWrapper: { className: 'content2-text', md: 14, xs: 24 },
  title: {
    className: 'content2-title k8na5sr94d9-editor_css',
    children: (
      <span>
        <p>Los docentes actuales</p>
      </span>
    ),
  },
  content: {
    className: 'content2-content k8na932mdmf-editor_css',
    children: (
      <span>
        <span>
          <p>
            Adquieren una reputación dentro de la comunidad educativa y ganan
            dinero con respecto a sus seguidores y patrocinadores en un mercado
            digital
          </p>
        </span>
      </span>
    ),
  },
};
export const Feature80DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature8-wrapper k8n9yj6q7wg-editor_css',
  },
  page: { className: 'home-page feature8' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature8-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature8-title-h1 k8n8wd53duc-editor_css',
        children: (
          <span>
            <span>
              <p>¿Cómo funciona?</p>
            </span>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'feature8-title-content k8n8xdry79o-editor_css',
        children: (
          <span>
            <span>
              <p>Es muy fácil e intuitivo sin importar la edad</p>
            </span>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: 'feature8-button-wrapper',
    children: [
      {
        name: 'button',
        className: 'feature8-button',
        children: {
          href: 'https://www.panzofi.com/core/introduccion-panzofi-para-docentes/',
          children: (
            <span>
              <p>Más información</p>
            </span>
          ),
          className: 'k8n93al3e0d-editor_css',
        },
      },
    ],
  },
  Carousel: {
    dots: false,
    className: 'feature8-carousel',
    wrapper: { className: 'feature8-block-wrapper' },
    children: {
      className: 'feature8-block',
      titleWrapper: {
        className: 'feature8-carousel-title-wrapper',
        title: { className: 'feature8-carousel-title' },
      },
      children: [
        {
          name: 'block0',
          className: 'feature8-block-row',
          gutter: 120,
          title: {
            className: 'feature8-carousel-title-block',
            children: '平台自主训练流程',
          },
          children: [
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child0',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child k8n95ueoiz-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/Verificar-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8na6b0yz5b-editor_css',
                    children: (
                      <span>
                        <p>1. Verificar</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8n8ybfikn-editor_css',
                    children: (
                      <span>
                        <span>
                          <span>
                            <p>
                              Verificar que no exista un contenido parecido. Ud
                              puede complementar el contenido en la sección de
                              práctica, o subir el contenido relacionado en un
                              mejor formato, o subir un tema nuevo
                            </p>
                          </span>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child1',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child k8n9rru8ty-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/upload-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8na6i8m03-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>2. Subir</p>
                        </span>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8n8yynxw0d-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>
                            Subir un curso en la plataforma. El contenido puede
                            ser un vídeo, texto, audio, infografía. Dependiendo
                            de las preferencias de los estudiantes si son
                            auditivas, visuales o de lectura
                          </p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child2',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://s3.amazonaws.com/panzofi.com/flecha-petit.svg',
              },
              children: {
                className: 'feature8-block-child k8n9ry8bkvs-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/share-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8na6oyxed-editor_css',
                    children: (
                      <span>
                        <p>3. Compartir</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8n8zagczbc-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>
                            Compartir el curso con sus estudiantes y fomentar la
                            participación en la sección de práctica. Los
                            estudiantes pueden subir ejemplos, ejercicios,
                            experimentos, testimonios entre otros
                          </p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
            {
              className: 'feature8-block-col',
              md: 6,
              xs: 24,
              name: 'child3',
              arrow: {
                className: 'feature8-block-arrow',
                children:
                  'https://gw.alipayobjects.com/zos/basement_prod/167bee48-fbc0-436a-ba9e-c116b4044293.svg',
              },
              children: {
                className: 'feature8-block-child k8n9s3ix8yq-editor_css',
                children: [
                  {
                    name: 'image',
                    className: 'feature8-block-image',
                    children:
                      'https://s3.amazonaws.com/panzofi.com/like-petit.svg',
                  },
                  {
                    name: 'title',
                    className: 'feature8-block-title k8na6t87osl-editor_css',
                    children: (
                      <span>
                        <p>4. Calificar</p>
                      </span>
                    ),
                  },
                  {
                    name: 'content',
                    className: 'feature8-block-content k8n8zd2x76i-editor_css',
                    children: (
                      <span>
                        <span>
                          <p>
                            Solicitar la calificación de los cursos para
                            identificar que secciones son las más divertidas,
                            eficientes y útiles. Permitirá mejorar el contenido
                            y ganar un reputación en la comunidad
                          </p>
                        </span>
                      </span>
                    ),
                  },
                ],
              },
            },
          ],
        },
      ],
    },
  },
};
export const Content40DataSource = {
  wrapper: {
    className: 'home-page-wrapper content4-wrapper k8n9yy0hj9-editor_css',
  },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>Únete gratis, sube contenido y participa ahora </p>
          </span>
        ),
        className: 'title-h1 k8n94jub04r-editor_css',
      },
      {
        name: 'content',
        className:
          'title-content content4-title-content k8n94tbkq0k-editor_css',
        children: (
          <span>
            <p>Se parte de esta revolución educativa</p>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video:
        'https://s3.amazonaws.com/panzofi.com/Pauta-redes+sociales.mp4',
      image: 'https://s3.amazonaws.com/panzofi.com/video-ima-ini.svg',
    },
  },
};
