import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import { enquireScreen } from 'enquire-js';
import ReactDOM from 'react-dom';


import About from './containers/SupportContent/About';
import Business from './containers/SupportContent/Business';
import Career from './containers/SupportContent/Career';

import Teachers from './containers/SupportContent/Teachers';
import Institutionedu from './containers/SupportContent/Institutionedu';

import CategoryContentList from './containers/CategoryContent/ListView';
import ContentList from './containers/HomeContent/ListView';
import CoreContentDetail from './containers/CoreContent/DetailView';
import CoreContentList from './containers/CoreContent/ListView';
import CoreContentUpdate from './containers/CoreContent/UpdateView';
import ExampleContentDetail from './containers/ExampleContent/DetailView';
import ExampleContentList from './containers/ExampleContent/ListView';
import ExampleContentUpdate from './containers/ExampleContent/UpdateView';
import Login from './containers/Authentication/Login';
import ProfileView from './containers/User/Profile';
import ProfileCreate from './containers/User/Create';
import Search from './containers/SearchContent/Search';
import SettingsView from './containers/User/Settings';
import Signup from './containers/Authentication/Signup';
import Support from './containers/SupportContent/Support';
import UploadAssist from './containers/UploadContent/UploadAssist';


const BaseRouter = () => (
  <div>
    <Route exact path='/' component={ContentList}/>
    <Route exact path='/about/' component={About}/>
    <Route exact path='/business/' component={Business}/>
    <Route exact path='/career/' component={Career}/>
    <Route exact path='/teachers/' component={Teachers}/>
    <Route exact path='/institutionedu/' component={Institutionedu}/>
    <Route exact path='/category/:slug' component={CategoryContentList}/>
    <Route exact path='/core/' component={CoreContentList}/>
    <Route exact path='/core/:slug/' component={CoreContentDetail}/>
    <Route exact path='/core/:slug/update/' component={CoreContentUpdate}/>
    <Route exact path='/example/' component={ExampleContentList}/>
    <Route exact path='/example/:slug/' component={ExampleContentDetail}/>
    <Route exact path='/example/:slug/update/' component={ExampleContentUpdate}/>
    <Route exact path='/login/' component={Login}/>
    <Route exact path='/profile/:slug/' component={ProfileView}/>
    <Route exact path='/profile/new/create/' component={ProfileCreate}/>
    <Route exact path='/search/' component={ContentList}/>
    <Route exact path='/search/:value/' component={Search}/>
    <Route exact path='/settings/:slug' component={SettingsView}/>
    <Route exact path='/signup/' component={Signup}/>
    <Route exact path='/support/' component={Support}/>
    <Route exact path='/upload/assist/' component={UploadAssist}/>
  </div>
);

export default BaseRouter;
