import React from 'react';
import { Link } from 'react-router-dom';

import { apiHost } from '../../config.dev.js';
import axios from 'axios';

import { Icon, Layout, Menu } from 'antd';

const { SubMenu } = Menu;
const { Sider } = Layout;

class Sidebar extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      categories:[]
    };
  }

  componentDidMount() {
    this.fetchCategories();
  }

  fetchCategories() {
    axios.get(`${apiHost}/api/v1/categories/`)
      .then(res => {
        this.setState({
          categories: res.data
        });
    })
  };

  render(){

    const catalogue = [];
    this.state.categories.forEach((category) => {
      if (!category.parent_category) {
        const subcatalogue = [];
        this.state.categories.forEach((subcategory) => {
          if (subcategory.parent_category === category.id) {
            subcatalogue.push(<Menu.Item key={subcategory.id}><Link to={`/category/${subcategory.slug}/`}>{subcategory.category}</Link></Menu.Item>)
          }
        })
        catalogue.push(
          <SubMenu style={{width:350, marginRight: 20}} key={category.id} title={category.category}>
            {subcatalogue}
          </SubMenu>
        );
      }
    });

    return(

        <Sider
          trigger={null}
          collapsible
          collapsed={this.props.collapsed}
          breakpoint="md"
          collapsedWidth="0"
          width="350"
          overlay="true"
        >

          <div className="logo"> <img src="/logo v2 - mini.png" className="logo-inner" alt="logo-favicon" /> </div>

          <Menu mode="inline" defaultSelectedKeys={['1']}>

            <Menu.Item key="home">
              <Link to='/'>
                <Icon type="home" />
                <span>Inicio</span>
              </Link>
            </Menu.Item>

            <SubMenu
              key="sub1"
              title={
                <span>
                  <Icon type="folder-open" />
                  <span>Catálogo</span>
                </span>
              }
            >
              {catalogue}
            </SubMenu>

            <SubMenu
              key="sub2"
              title={
                <span>
                  <Icon type="appstore" />
                  <span>Cursos</span>
                </span>
              }
            >
              <Menu.Item key="core"><Link to='/core/'>Teóricos</Link></Menu.Item>
              <Menu.Item key="example"><Link to='/example/'>Prácticos</Link></Menu.Item>
            </SubMenu>
            
            <Menu.Item key="about">
              <Link to='/about/'>
                <Icon type="rocket" />
                <span>Sobre nosotros</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="business">
              <Link to='/business/'>
                <Icon type="shop" />
                <span>Empresas</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="teachers">
              <Link to='/teachers/'>
                <Icon type="read" />
                <span>Docentes</span>
              </Link>
            </Menu.Item>
            
            <Menu.Item key="institutionedu">
              <Link to='/institutionedu/'>
                <Icon type="bank" />
                <span>Intituciones de educación</span>
              </Link>
            </Menu.Item>

            <Menu.Item key="career">
              <Link to='/career/'>
                <Icon type="solution" />
                <span>Trabaja con nosotros</span>
              </Link>
            </Menu.Item>




          </Menu>

        </Sider>

    );
  }
}

export default Sidebar;
