import React from 'react';

import MediaQuery from 'react-responsive';

import { connect } from 'react-redux';
import { beforeUpload, getBase64 } from '../../../file-processor-api';

import { AutoComplete, Button, Col, Form, Icon, Input, Row, Select, Typography, Upload } from 'antd';

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const AutoCompleteOption = AutoComplete.Option;

class CreateProfile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      autoCompleteResult: [],
      loading: false,
      introduction: '',
      location: '',
      website: '',
      phone:  '',
      imageUrl: ''
    }
  }

  introduction = (event) => {
    event.persist();
    this.setState({
      introduction : event.target.value
    });
  };

  location = (event) => {
    event.persist();
    this.setState({
      location : event.target.value
    });
  };

  phone = (event) => {
    event.persist();
    this.setState({
      phone: event.target.value
    });
  };

  website = (value) => {

    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net', '.co.in'].map(domain => `${value}${domain}`);
    }

    this.setState({
      website: value,
      autoCompleteResult
    });
  };

  avatar = info => {
     if (info.file.status === 'uploading') {
       this.setState({ loading: true });
       return;
     }
     if (info.file.status === 'done') {
       // Get this url from response in real world.
       getBase64(info.file.originFileObj, imageUrl => {
         this.setState({
           imageUrl,
           loading: false,
         });
       });
     }
   };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.create(this.state);
  };

  render() {

    const { imageUrl } = this.state;
    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Subir</div>
      </div>
    );

    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '58',
    })(
      <Select style={{ width: 70 }}>
        <Option value={52}>+52</Option>
        <Option value={57}>+57</Option>
        <Option value={58}>+58</Option>
      </Select>,
    );

    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));

    return(
      <div>
      <Title level={4} style={{padding: 10, textAlign:"center"}}>Parece que eres nuevo aquí. Comencemos con tu perfil. </Title>

      <MediaQuery minDeviceWidth={1024}>
        {(matches) =>
        matches
          ? <Row>
          <Col span={12} offset={6}>
          <Form style={{marginTop: 50}} onSubmit={(event) => this.handleSubmit(event)}>

             <Form.Item label="Descripción del perfil">
                <TextArea name="introduction" onChange={this.introduction} placeholder="Actualiza su perfil. ¿Tienes algo interesante para compartir con tus seguidores?" />
              </Form.Item>

              <Form.Item label="Ubicación">
                <Input name="location"  onChange={this.location} placeholder="¿A qué parte del mundo perteneces?" />
              </Form.Item>

              <Form.Item label="Sitio web">
                {getFieldDecorator('website', {
                  rules: [{ required: false, message: 'Por favor ingresa el sitio web' }],
                })(
                  <AutoComplete
                    dataSource={websiteOptions}
                    onChange={this.website}
                    placeholder="¿Tienes una página web? ¡No olvides incluir el prefijo https!"
                >
                  <Input />
                </AutoComplete>,
              )}
            </Form.Item>

            <Form.Item label="Número de teléfono">
                {getFieldDecorator('phone', {
                  rules: [{ required: false, message: 'Por favor ingresa tu número de teléfono' }],
              })(<Input addonBefore={prefixSelector} onChange={this.phone} style={{ width: '100%' }} />)}
            </Form.Item>

            <Text>Haz clic a continuación para actualizar tu foto de perfil. El tipo admitido incluye <i> jpeg </i> y <i> png </i>. Límite máximo de carga <i> 2MB </i></Text>

            <Form.Item style={{marginTop: 20, padding: 10}}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={this.avatar}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
            </Form.Item>

            <Form.Item>
              <Row>
                <Col justify="start">
                <Col span={6}>
                  <Button type="primary" htmlType="submit"> Crear perfil </Button>
                </Col>
                <Col span={6} offset={1}>
                  <Button href={`/`}> Volver al inicio </Button>
                </Col>
                </Col>
              </Row>
            </Form.Item>

          </Form>
          </Col>
          </Row>
          : <Row>
          <Col>
          <Form style={{marginTop: 50}} onSubmit={(event) => this.handleSubmit(event)}>

            <Form.Item label="Descripción del perfil">
              <TextArea name="introduction" onChange={this.introduction} placeholder="Actualiza su perfil. ¿Tienes algo interesante para compartir con tus seguidores?" />
            </Form.Item>

            <Form.Item label="Ubicación">
              <Input name="location"  onChange={this.location} placeholder="¿A qué parte del mundo perteneces?" />
            </Form.Item>

            <Form.Item label="Sitio web">
              {getFieldDecorator('website', {
                rules: [{ required: false, message: 'Por favor ingresa el sitio web' }],
              })(
                <AutoComplete
                  dataSource={websiteOptions}
                  onChange={this.website}
                  placeholder="¿Tienes una página web? ¡No olvides incluir el prefijo https!"
                >
                  <Input />
                </AutoComplete>,
              )}
            </Form.Item>

            <Form.Item label="Número de teléfono">
                {getFieldDecorator('phone', {
                  rules: [{ required: false, message: 'Por favor ingresa tu número de teléfono' }],
              })(<Input addonBefore={prefixSelector} onChange={this.phone} style={{ width: '100%' }} />)}
            </Form.Item>

            <Text>Haz clic a continuación para actualizar tu foto de perfil. El tipo admitido incluye <i> jpeg </i> y <i> png </i>. Límite máximo de carga <i> 2MB </i></Text>

            <Form.Item style={{marginTop: 20, padding: 10}}>
            <Upload
              name="avatar"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
              beforeUpload={beforeUpload}
              onChange={this.avatar}
            >
              {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
            </Upload>
            </Form.Item>

            <Form.Item>
            <MediaQuery minDeviceWidth={768}>
              {(matches) =>
              matches
                ? <Row>
                  <Col span={4}>
                    <Button type="primary" htmlType="submit" >Crear perfil</Button>
                  </Col>
                  <Col span={4} offset={1}>
                    <Button type="default" href={`/}`} >Volver al inicio</Button>
                  </Col>
                </Row>
                : <div>
                  <Row>
                    <Col>
                      <Button type="primary" htmlType="submit" block>Crear perfil</Button>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Button type="default" href={`/}`} block>Volver al inicio</Button>
                    </Col>
                  </Row>
                </div>
              }
            </MediaQuery>

            </Form.Item>

          </Form>
          </Col>
          </Row>
        }
        </MediaQuery>


      </div>
    );
  };
}

const WrappedProfileCreateLayout = Form.create({ name: 'profile-create' })(CreateProfile);


export default connect(null)(WrappedProfileCreateLayout);
