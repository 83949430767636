import React from 'react';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import { Avatar, Dropdown, Menu } from 'antd';

class UserBubble extends React.Component {

  state = {
    exists: true,
    avatar: '',
    slug: ''
  };

  logout = () => {
    this.setState({
      avatar: '',
      slug: ''
    });

    this.props.logout();
  }

  profile = () => {
    if(!this.state.exists) {
      this.props.history.push({
        pathname:'/profile/new/create/'
      });
    }
    if(this.state.slug) {
      this.props.history.push({
          pathname: `/profile/${this.state.slug}`,
          keyword: this.state.slug
      });
    }

  }

  settings = () => {

    if(!this.state.exists) {
      this.props.history.push({
        pathname:'/profile/new/create/'
      });
    }

    if(this.state.exists && localStorage.getItem('username')) {
      this.props.history.push({
        pathname: `/settings/${this.state.slug}`,
        keyword: this.state.slug
      });
    }
  }

  support = () => {
    this.props.history.push('/support/');
  }

  componentDidMount() {

      axios.get(`${apiHost}/api/v1/profile/`)
        .then(res => {
          let exists = false;
          let avatar = '';
          let slug = '';
          res.data.forEach(item => {
            if(item.username === localStorage.getItem('username')) {
              avatar = item.profile_picture;
              slug = item.slug;
              exists = true;
            };
          });

          if(!exists) {
              this.setState({
                exists: exists
              })
          }

          if(avatar !== null && avatar !== '') {
            this.setState({
              avatar: avatar
            });
          }

          this.setState({
            slug: slug
          });

        });

  }

  componentDidUpdate(prevProps, prevState) {
    if(prevState.avatar !==  this.state.avatar  ||  prevProps !== this.props) {
      this.componentDidMount();
      return true;
    };
    return false;
  }

  render() {

    const menu = (
      <Menu style={{ width: 180}}>
        <Menu.Item key="1" onClick={this.profile}>
          Mi perfil
        </Menu.Item>
        <Menu.Item key="2" onClick={this.settings}>
          Ajustes
        </Menu.Item>
        <Menu.Item key="3" onClick={this.support}>
          Soporte
        </Menu.Item>

        <Menu.Divider />
        <Menu.Item key="4" onClick={this.logout}>
          Cerrar sesión
        </Menu.Item>
      </Menu>
    );

    if(this.state.avatar) {
        return (
            <Dropdown overlay={menu}>
              <Avatar src={this.state.avatar} className="user-bubble" style={{ marginRight: 20, backgroundColor: '#dddddd'}} size="large" icon="user"  />
            </Dropdown>
          );
    } else {
        return (
            <Dropdown overlay={menu}>
              <Avatar src="https://s3.amazonaws.com/panzofi.com/Avatar.png4" className="user-bubble" style={{ marginRight: 20, backgroundColor: '#dddddd'}} size="large" icon="user"  />
            </Dropdown>
          );
      };

  };

}

export default UserBubble;
