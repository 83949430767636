import React from 'react';

import * as emailjs from 'emailjs-com'
import MediaQuery from 'react-responsive';

import { Button, Col, Divider, Form, Icon, Input, notification, Layout, Radio, Row, Typography } from 'antd';

import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';

import moment from 'moment';
import 'moment/locale/es';

moment.locale('en');

const { Paragraph, Text, Title } = Typography;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}


class Footer extends React.Component {

  handleSubmit = e => {

    e.preventDefault();

    const openNotificationWithIcon = (type, status, msg) => {
      notification[type]({
        message: status,
        description: msg
        });
    };

    this.props.form.validateFields((err, values) => {
      if (!err) {
       let templateParams = {
          from_name: values.email,
          message_html: "Gracias por suscribirse al boletín de Panzofi. Panzofi es una plataforma de aprendizaje, cuyo objetivo es democratizar la educación en todo el mundo sin importar las fronteras. ¡Gracias por unirse a nosotros!",
         }
       emailjs.send(
        'gmail',
        'template_6rBp96so',
         templateParams,
        'user_J7LVrcvANkbHmnxbw7TJM'
       )
       .then(function(response) {
           openNotificationWithIcon('success', 'Suscrito!', "Gracias por suscribirse al boletín de Panzofi. Por favor revise su bandeja de entrada.")

        }, function(error) {
          openNotificationWithIcon('error', '¡Falló!',' Error de red. Inténtalo de nuevo luego.')

        });
      }
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;

    return(

      <Layout.Footer style={{ textAlign: 'center' }} >

      <MediaQuery minDeviceWidth={1024}>
        {(matches) =>
        matches
          ? <div>

            <Row>

          <Col span={10}>
            <Row>
              <Col style={{ textAlign: "left", marginTop: 15}}>
                <Text style={{ fontSize: 20 }}  strong>@Panzofi</Text>
                <Paragraph style={{ marginTop: 20 }} >“Estamos en una misión para democratizar la educación en todo el mundo sin importar las fronteras. Tu imaginación y motivación son tus límites ... "   </Paragraph>
              </Col>
            </Row>
          </Col>

          <Divider type="vertical" />

          <Col span={12} offset={2} style={{ textAlign: "center"}}>

                <Text style={{ fontSize: 20 }} strong> ¡Mantente en contacto! </Text> <br /><br />
                <Text type="secondary">Suscríbete a nuestro boletín y entérate de nuestros nuevos lanzamientos. </Text>

                <Form style={{ marginTop: 15}} layout="inline" onSubmit={this.handleSubmit}>
                  <Form.Item>
                    {getFieldDecorator('email', {
                      rules: [
                        {
                          type: 'email',
                          message: '¡La entrada no es válida E-mail!',
                        },
                        {
                          required: true,
                          message: 'Por favor ingrese su correo electrónico',
                        },
                      ],
                    })(
                      <Input
                        style={{ width: 300}}
                        prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        placeholder="Correo electrónico"
                      />,
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Button htmlType="submit" type="primary" >
                      Suscríbete
                    </Button>
                  </Form.Item>
                </Form>

          </Col>

            </Row>

            <Row type="flex" justify="start" style={{ textAlign: "left"}}>
              <Col span={3} style={{ marginTop: 5}}>
                <Text underline>Explorar</Text>
                <Paragraph style={{ marginTop: 5}}>
                   
                   <Text><a href='/about/'>Sobre nosotros</a></Text><br/>
                   <Text><a href='/business/'>Empresas</a></Text><br/>
                   <Text><a href='/teachers/'>Docentes</a></Text><br/>
                   <Text><a href='/institutionedu/'>Instituciones de educación</a></Text><br/>
                   <Text><a href='/career/'>Empleo</a></Text><br/>
                </Paragraph>
              </Col>

              <Col span={4} style={{ marginTop: 5}}>
                <Text underline>Términos y condiciones</Text>
                <Paragraph style={{ marginTop: 5}}>
                    <Text><a href='/'>Términos de uso</a></Text><br/>
                    <Text><a href='/'>Privacidad</a></Text><br/>
                </Paragraph>
              </Col>

              <Col span={4} style={{ marginTop: 5}}>
                <Text underline>Comunidad</Text>
                <Paragraph style={{ marginTop: 5}}>
                    <Text><a href='/'>Blog</a></Text><br/>
                    <Text><a href='/support/'>Contacto</a></Text><br/>
                    <Text><a href='/support/'>Reportar falla</a></Text><br/>
                </Paragraph>
              </Col>

              <Col span={10} offset={2} style={{ marginTop: 5, textAlign: "center" }}>
              <br />
              <Text type="secondary"> Visítanos en tu cuenta de redes sociales preferida y <strong> únete a la comunidad </strong>.</Text>
                <div style={{ fontSize: 30}}>
                    <Icon type="facebook"/>
                    <Divider type="vertical" />

                    <Icon type="youtube" />
                    <Divider type="vertical" />

                    <Icon type="linkedin" />
                </div>
              </Col>
            </Row>

            <Divider />

            <Row>
              <Col span={10} style={{ textAlign: "left" }}>
                Panzofi <Icon type="copyright" /> 2020. Todos los derechos reservados
              </Col>

              <Col span={8} offset={6} style={{ textAlign: "right" }}>
                    <div className="change-locale">
                      <Radio.Group buttonStyle="solid" value={this.props.value} onChange={this.props.callback}>
                        <Radio.Button key="en" value={enUS}>
                          English
                        </Radio.Button>
                        <Radio.Button key="es" value={esES}>
                          Español
                        </Radio.Button>
                      </Radio.Group>
                    </div>

              </Col>
            </Row>

          </div>
          : <div>
            <MediaQuery minDeviceWidth={540}>
            {(matches) =>
            matches
            ? <Row>
            <Col style={{ marginTop: 5 }}>
            <Text type="secondary">Suscríbete a nuestro boletín y entérate de nuestros nuevos lanzamientos. </Text>
            <Form style={{ marginTop: 15}} layout="inline" onSubmit={this.handleSubmit}>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: '¡La entrada no es válida E-mail!',
                    },
                    {
                      required: true,
                      message: 'Por favor ingrese su correo electrónico',
                    },
                  ],
                })(
                  <Input
                    style={{ width: "100%"}}
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Correo electrónico"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary" >
                  Suscríbete
                </Button>
              </Form.Item>
            </Form>
            </Col>
            </Row>
            :
            <Row>
            <Col style={{ marginTop: 5 }}>
            <Text type="secondary">Suscríbete a nuestro boletín y entérate de nuestros nuevos lanzamientos. </Text>
            <Form style={{ marginTop: 15}} onSubmit={this.handleSubmit}>
              <Form.Item>
                {getFieldDecorator('email', {
                  rules: [
                    {
                      type: 'email',
                      message: '¡La entrada no es válida E-mail!',
                    },
                    {
                      required: true,
                      message: 'Por favor ingrese su correo electrónico',
                    },
                  ],
                })(
                  <Input
                    style={{ width: "100%"}}
                    prefix={<Icon type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
                    placeholder="Correo electrónico"
                  />,
                )}
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary" >
                  Suscríbete
                </Button>
              </Form.Item>
            </Form>
            </Col>
            </Row>
            }
            </MediaQuery>

            < Divider />

            <Paragraph style={{ marginTop: 20, textAlign: "center", fontSize: 20}}>
              <Text underline>Enlaces rápidos</Text>
            </Paragraph>

            <MediaQuery minDeviceWidth={540}>
            {(matches) =>
            matches
              ? <Row type="flex" justify="start" style={{ textAlign: "center"}}>

                <Col span={8} style={{ marginTop: 5}}>
                  <Text underline>Explorar</Text>
                  <Paragraph style={{ marginTop: 5}}>
                     <Text><a href='/about/'>Sobre nosotros</a></Text><br/>
                     <Text><a href='/business/'>Empresas</a></Text><br/>
                     <Text><a href='/career/'>Empleo</a></Text><br/>
                  </Paragraph>
                </Col>

                <Col span={8} style={{ marginTop: 5}}>
                  <Text underline>Términos y condiciones</Text>
                  <Paragraph style={{ marginTop: 5}}>
                      <Text><a href='/'>Términos de uso</a></Text><br/>
                      <Text><a href='/'>Privacidad</a></Text><br/>
                  </Paragraph>  
                </Col>

                <Col span={8} style={{ marginTop: 5}}>
                  <Text underline>Comunidad</Text>
                  <Paragraph style={{ marginTop: 5}}>
                      <Text><a href='/'>Blog</a></Text><br/>
                      <Text><a href='/support/'>Contacto</a></Text><br/>
                      <Text><a href='/support/'>Reportar falla</a></Text><br/>
                  </Paragraph>
                </Col>

              </Row>
              : <Row type="flex" justify="start" style={{ textAlign: "center"}}>

                <Col span={8} style={{ marginTop: 5}}>
                  <Text underline>Explorar</Text>
                  <Paragraph style={{ marginTop: 5}}>
                     <Text><a href='/about/'>Sobre nosotros</a></Text><br/>
                     <Text><a href='/business/'>Empresas</a></Text><br/>
                     <Text><a href='/career/'>Empleo</a></Text><br/>
                  </Paragraph>
                </Col>

                <Col span={8} style={{ marginTop: 5}}>
                  <Text underline>Términos</Text>
                  <Paragraph style={{ marginTop: 5}}>
                      <Text><a href='/'>Uso</a></Text><br/>
                      <Text><a href='/'>Privacidad</a></Text><br/>
                  </Paragraph>
                </Col>

                <Col span={8} style={{ marginTop: 5}}>
                  <Text underline>Comunidad</Text>
                  <Paragraph style={{ marginTop: 5}}>
                      <Text><a href='/'>Blog</a></Text><br/>
                      <Text><a href='/support/'>Contacto</a></Text><br/>
                      <Text><a href='/support/'>Falla</a></Text><br/>
                  </Paragraph>
                </Col>

              </Row>
            }
            </MediaQuery>

            <Divider />

            <Row>
              <Col span={24} style={{ textAlign: "center" }}>

                    <div className="change-locale">

                      <Radio.Group buttonStyle="solid" value={this.props.value} onChange={this.props.callback}>

                        <Radio.Button key="en" value={enUS}>
                          English
                        </Radio.Button>

                        <Radio.Button key="es" value={esES}>
                          Español
                        </Radio.Button>

                      </Radio.Group>

                    </div>

              </Col>

              <Col span={24} style={{ textAlign: "center", marginTop: 20 }}>
                Panzofi <Icon type="copyright" /> 2020. Todos los derechos reservados
              </Col>
            </Row>

          </div>
        }
      </MediaQuery>

      </Layout.Footer>

    );
  }
}

const WrappedSubscriptionForm = Form.create({ name: 'subscription' })(Footer);

export default WrappedSubscriptionForm;
