import React from 'react';

import MediaQuery from 'react-responsive';

import { connect } from 'react-redux';

import { AutoComplete, Button, Col, Form, Icon, Input, Row, Select, Spin, Typography } from 'antd';

const { Title } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const AutoCompleteOption = AutoComplete.Option;
const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class Profile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      autoCompleteResult: [],
      profile_introduction: this.props.profile.profile_introduction,
      location: this.props.profile.location,
      phone: this.props.profile.phone,
      website: this.props.profile.website
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    let updateProfile = {
      profile_introduction: this.state.profile_introduction,
      location: this.state.location,
      website: this.state.website,
      phone: this.state.phone,
      profile_picture: 'default'
    }
    this.props.updateProfile(updateProfile);
  };


  introduction = (event) => {
    event.persist();
    this.setState({
      profile_introduction: event.target.value
    });
  };

  location = (event) => {
    event.persist();
    this.setState({
      location : event.target.value
    });
  };

  phone = (event) => {
    event.persist();
    this.setState({
      phone: event.target.value
    });
  };

  website = (value) => {

    let autoCompleteResult;
    if (!value) {
      autoCompleteResult = [];
    } else {
      autoCompleteResult = ['.com', '.org', '.net', '.co.in'].map(domain => `${value}${domain}`);
    }

    this.setState({
      website: value,
      autoCompleteResult
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;
    const { autoCompleteResult } = this.state;

    const prefixSelector = getFieldDecorator('prefix', {
      initialValue: '58',
    })(
      <Select style={{ width: 70 }}>
        <Option value={52}>+52</Option>
        <Option value={57}>+57</Option>
        <Option value={58}>+58</Option>
      </Select>,
    );

    const websiteOptions = autoCompleteResult.map(website => (
      <AutoCompleteOption key={website}>{website}</AutoCompleteOption>
    ));

    return(
        <React.Fragment>
        {
          this.props.sending?
          <div className="loader">
            <Spin tip="¡Gran actualización! Espera mientras validamos tu perfil en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
          </div>
          :
          <div>
            <Title level={3}>Actualiza tu tarjeta de perfil</Title>
            <Form style={{marginTop: 50}} onSubmit={ (event) => this.handleSubmit(event)}>

              <Form.Item label="Descripción del perfil">
                <TextArea name="introduction" onChange={this.introduction} placeholder="Actualiza su perfil. ¿Tienes algo interesante para compartir con tus seguidores?" />
              </Form.Item>

              <Form.Item label="Ubicación">
                <Input name="location"  onChange={this.location} placeholder="¿A qué parte del mundo perteneces?" />
              </Form.Item>

              <Form.Item label="Sitio web">
                {getFieldDecorator('website', {
                  rules: [{ required: false, message: 'Por favor ingresa el sitio web' }],
                })(
                  <AutoComplete
                    dataSource={websiteOptions}
                    onChange={this.website}
                    placeholder="¿Tienes una página web? ¡No olvides incluir el prefijo https!"
                  >
                    <Input />
                  </AutoComplete>,
                )}
              </Form.Item>

              <Form.Item label="Número de teléfono">
                {getFieldDecorator('phone', {
                  rules: [{ required: false, message: 'Por favor ingresa tu número de teléfono' }],
                })(<Input addonBefore={prefixSelector} onChange={this.phone} style={{ width: '100%' }} />)}
              </Form.Item>

              <Form.Item>
                <MediaQuery minDeviceWidth={768}>
                  {(matches) =>
                  matches
                    ? <Row>
                      <Col span={4}>
                        <Button type="primary" htmlType="submit" >Actualización del perfil</Button>
                      </Col>
                      <Col span={4} offset={1}>
                        <Button type="default" href={`/`} >Volver al inicio</Button>
                      </Col>
                    </Row>
                    : <div>
                      <Row>
                        <Col>
                          <Button type="primary" htmlType="submit" block>Actualizar</Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button type="default" href={`/`} block>Inicio</Button>
                        </Col>
                      </Row>
                    </div>
                  }
                </MediaQuery>

              </Form.Item>

            </Form>
          </div>
        }
        </React.Fragment>

      );
    };

};

const WrappedProfileSettingLayout = Form.create({ name: 'profile-setting' })(Profile);


export default connect(null)(WrappedProfileSettingLayout);
