import React from 'react';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import MediaQuery from 'react-responsive';

import CoreContent from '../../components/Content/Core/Core';
import CoreContentResponsive from '../../components/Content/Core/CoreResponsive';
import './CoreContent.css';

class CoreContentList extends React.Component {

  state = {
    content: []
  }

  componentDidMount() {
      axios.get(`${apiHost}/api/v1/core/`)
        .then(res => {
          this.setState({
            content: res.data
          });
        })
  }

  render() {
    return (
      <MediaQuery minDeviceWidth={1024}>
        {(matches) =>
        matches
          ? <CoreContent data={this.state.content}/>
          : <CoreContentResponsive data={this.state.content}/>
        }
      </MediaQuery>
    )
  }

}

export default CoreContentList
