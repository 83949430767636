import React from 'react';

import MediaQuery from 'react-responsive';

import { Button, Col, Form, Icon, Input, Row, Select, Spin, Typography } from 'antd';

const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;

const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;

class Essentials extends React.Component {

  state = {
    category: 1,
    title: this.props.init_content.title,
    description: this.props.init_content.description
  }

  handleFormSubmit = (event) => {
    event.preventDefault();
    let updateContent = {
      category:this.state.category,
      title:this.state.title,
      description:this.state.description,
      thumbnail:'default',
      infographics:'default',
      video:'default',
      audio:'default',
      textArticle: 'default',
      previous: 'default',
      next:'default',

    }
    this.props.updateContent(updateContent);
  }

  render() {

    const categories = [];
    this.props.categories.forEach((category) => {
        categories.push(<Option key={category.id}>{category.category}</Option>);
    });

    return(
      <React.Fragment>
      {
        this.props.sending?
        <div className="loader">
          <Spin tip="¡Gran actualización! Espera mientras validamos tu contenido en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
        </div>
       :
        <Form onSubmit={(event) => this.handleFormSubmit(event)}>

        <Text strong>Coloca el curso dentro del catálogo apropiado.</Text><br />
        <Form.Item style={{marginTop: 20}}>
          <Select
            showSearch
            placeholder="selecciona la categoría para tu contenido"
            optionFilterProp="children"
            onChange={(value) => {
              this.setState({category : value});
            }}
            onSearch={(value) => {
              this.setState({category : value});
            }}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {categories}

          </Select>
        </Form.Item>

        <Text strong>¿Cuál preferirías que sea el título del curso? </Text><br />
        <Form.Item style={{marginTop: 20}}>
          <Input

          onChange={e => {
            e.persist();
            this.setState({title: e.target.value});
          }}
          placeholder="dale un título interesante a tu curso. Límite de palabras: 40" />
        </Form.Item>

        <Text strong>Describe brevemente sobre el contenido del curso. </Text> <br />
        <Form.Item style={{marginTop: 20}}>
          <TextArea

          onChange={e => {
            e.persist();
            this.setState({description: e.target.value});
          }}
          placeholder="escribe una descripción sobre tu curso" rows={4} />
        </Form.Item>

        <Form.Item>
        <MediaQuery minDeviceWidth={768}>
          {(matches) =>
          matches
            ? <Row>
              <Col span={5}>
                <Button type="primary" htmlType="submit" >Actualización del curso</Button>
              </Col>
              <Col span={4} offset={1}>
                <Button type="default" href={`/core/${this.props.init_content.slug}`} >Ver curso</Button>
              </Col>
            </Row>
            : <div>
              <Row>
                <Col>
                  <Button type="primary" htmlType="submit" block>Actualización del curso</Button>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="default" href={`/core/${this.props.init_content.slug}`} block>Ver curso</Button>
                </Col>
              </Row>
            </div>
          }
        </MediaQuery>
        </Form.Item>
        </Form>
      }
      </React.Fragment>
    );
  }
}

export default Essentials;
