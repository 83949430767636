import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as actions from './store/actions/auth';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import { enquireScreen } from 'enquire-js';

import Base from './containers/Layout/Base';
import BaseRouter from './routes';

import 'antd/dist/antd.less';
import './App.css'

import { ConfigProvider } from 'antd';

import enUS from 'antd/es/locale/en_US';
import esES from 'antd/es/locale/es_ES';
import moment from 'moment';
import 'moment/locale/es';


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      locale: esES,
      isMobile, 
    }
  };

  changeLocale = e => {
    const localeValue = e.target.value;
    this.setState({ locale: localeValue });
    if (!localeValue) {
      moment.locale('en');
    } else {
      moment.locale('es');
    }
  };

  componentDidMount() {
  this.props.onTryAutoSignup();
  enquireScreen((b) => {
      this.setState({ isMobile: !!b });
  });
};

  render() {
    const { locale } = this.state;

    return (
      <div>
      <ConfigProvider locale={locale}>
        <Router>

            <Base {...this.props} locale={locale} key={locale ? locale.locale : 'en'} changeLocale={this.changeLocale}>
              <BaseRouter />
            </Base>

        </Router>
      </ConfigProvider>
      </div>
    );
  };
}

const mapStateToProps = state => {
  return {
    isAuthenticated: state.token !== null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch(actions.authCheckState())
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(App);
