import { notification } from 'antd';

export const fileHandler = function() {
  console.log('Lets go');
}

export const loadXHR = function(url) {
  return new Promise(function(resolve, reject) {
    try {
      var xhr = new XMLHttpRequest();
      xhr.open("GET", url);
      xhr.responseType = "blob";
      xhr.onerror = function() {reject("Network error.")};
      xhr.onload = function() {
        xhr.status === 200? resolve(xhr.response) : reject("Loading error:" + xhr.statusText)
      };
      xhr.send();
    }
    catch(err) {reject(err.message)}
  });
};

export const getBase64 = function(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

export const beforeUpload = function(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
      notification['error']({
        message: 'La carga de la imagen falló',
        description: '¡Solo puedes subir archivos .jpg o .png!'
      });
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    notification['error']({
      message: 'La carga de la imagen falló',
      description: '¡La imagen debe tener menos de 2 MB!'
    });

  }
  return isJpgOrPng && isLt2M;
};
