import React from 'react';
import { connect } from 'react-redux';
import cookie from "react-cookies";

import ReactPlayer from 'react-player';
import ReactAudioPlayer from 'react-audio-player';

import axios from 'axios';
import { apiHost } from '../../../config.dev.js';

import MediaQuery from 'react-responsive';

import { beforeUpload, getBase64 } from '../../../file-processor-api';

import { Avatar, Button, Card, Col, Divider, Icon, Input, List, Modal, message, notification, Row, Spin, Tooltip, Typography, Upload} from 'antd';

const ReactMarkdown = require('react-markdown')
const htmlParser = require('react-markdown/plugins/html-parser')

const { TextArea } = Input
const { Paragraph, Text, Title } = Typography;
const { Dragger } = Upload;

const loggedUser = localStorage.getItem('username');
const spinIcon = <Icon type="loading" className="loader-icon" style={{ fontSize : 100 }} spin />;


class CoreDetail extends React.Component{

  state = {
    sending: false,
    visible: false,
    status: 'fail',
    title: '',
    description: '',
    imgUrl: '',
    category: ''
   };

  componentDidMount() {
    axios.get(`${apiHost}/api/v1/categories/`)
      .then(res => {
        res.data.forEach((cat) => {
            if (cat.category === this.props.core.category) {
              this.setState({
                category: cat.id
              });
            }
      });
      });
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = e => {

    if(!loggedUser){
      message.info('Se requiere iniciar sesión para esta acción', 5);
      return;
    }

    const openNotification = (type, msg, des) => {
      notification[type]({
        message: msg,
        description: des
      });
    };

    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.headers = {
      "Authorization": 'Token '+ this.props.token,
      "X-CSRFTOKEN": cookie.load("csrftoken")
    }

    const fd = new FormData();

    if(!this.state.title || !this.state.description) {
      openNotification('error', 'Datos faltantes','Título y Descripción son campos obligatorios');
    } else {

      this.setState({
        sending: !this.state.sending
      });

      fd.append("title", this.state.title);
      fd.append("description", this.state.description);
      fd.append("core_relation", this.props.core.id);
      fd.append("thumbnail", this.state.imgUrl);
      !this.state.videoUrl? fd.append("video", '') : fd.append("video", this.state.videoUrl);
      fd.append("category", this.state.category);


      return axios.post(`${apiHost}/api/v1/example/create/`, fd)
      .then(res => {
        if(res.status === 201) {
          this.setState({
            visible: false,
            status: 'success',
            sending: !this.state.sending
          });
          openNotification('success', 'Hecho','El curso se ha registrado con éxito.');
        };
      })
      .catch(err => {
        this.setState({
          visible: false,
          status: "error",
          sending: !this.state.sending
        });
        openNotification('error', 'Error de red', JSON.stringify(err.response.data));
      })
    }





  };

  handleCancel = e => {
    this.setState({
      visible: false,
    });
  };

  thumbnailUpload = info => {
     if (info.file.status === 'uploading') {
       this.setState({ loading: true });
       return;
     }
     if (info.file.status === 'done') {
       // Get this url from response in real world.
       getBase64(info.file.originFileObj, imgUrl => {
         this.setState({
           imgUrl,
           loading: false,
         });
       });
     }
  };

  videoUpload = info => {

    if (info.fileList.length > 1 ){
      info.fileList.shift();
    }

    this.setState({videoUrl: info.fileList[0].originFileObj});

  };

  render() {
    let src="";

    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Subir</div>
      </div>
    );

    const { imgUrl } = this.state;

    return(
      <div>
        {
          this.props.loggedUser === this.props.core.author ?
          <div>
            <Row>
              <Col>
                <Button href='/core/' type="primary" block> Explora otros cursos teóricos </Button>
              </Col>
            </Row>

            <Row style={{marginTop: 20}}>
                <Col span={11}>
                  <Button href={`/core/${this.props.slug}/update/`} block> Actualizar </Button>
                </Col>
                <Col span={11} offset={2}>
                  <Button onClick={this.props.showDeleteConfirm} type="danger" block> Eliminar </Button>
                </Col>
            </Row>
            <Divider />
          </div>
          :
          <div>
            <Row>
              <Col>
                <Button href='/core/'  type="primary" block> Explora otros cursos teóricos </Button>
              </Col>
            </Row>
            <Divider />
          </div>
        }

        <div>
          <Card
            hoverable={true}
            bordered={true}
            actions={
              [
                <Tooltip title="Divertido">
                  <span>
                     <Icon style={{ marginRight: 8, fontSize: '20px' }} type="smile" theme="twoTone" twoToneColor="#f67177" onClick={this.props.funHandler} key="list-vertical-smile-o"/>{this.props.fun}
                  </span> </Tooltip>,
                <Tooltip title="No divertido">
                  <span>
                     <Icon style={{ marginRight: 8, fontSize: '20px'  }} type="frown" theme="twoTone" twoToneColor="#f67177" onClick={this.props.nofunHandler} key="list-vertical-frown-o"/>{this.props.no_fun}
                  </span> </Tooltip>,
                <Tooltip title="Eficiente">
                 <span>
                     <Icon style={{ marginRight: 8, fontSize: '20px'  }} type="star" theme="twoTone" twoToneColor="#f67177" onClick={this.props.efficientHandler} key="list-vertical-star-o" />{this.props.efficient}
                 </span> </Tooltip>,
                <Tooltip title="Útil">
                 <span>
                     <Icon style={{ marginRight: 8, fontSize: '20px'  }} type="bulb" theme="twoTone" twoToneColor="#f67177" onClick={this.props.usefulHandler} key="list-vertical-bulb-o" />{this.props.useful}
                 </span> </Tooltip>,

                <Tooltip title="Vistas totales">
                  <span>
                     <Icon style={{ marginRight: 8, fontSize: '20px'  }} type="eye" theme="twoTone" twoToneColor="#f67177" key="list-vertical-eye" />{this.props.core.total_views}
                  </span>
                </Tooltip>
              ]}
          >
            <h1 style={{ marginTop: 10 }}>{this.props.core.title}</h1>
            <small> Autor: { this.props.core.author } | Publicado en: { this.props.core.publish } </small>
            <Divider />

            <ReactPlayer
              url={this.props.core.video}
              playing={false}
              controls={true}
              height='200'
              width='300'
            />

          </Card>

          <MediaQuery minDeviceWidth={768}>
            {(matches) =>
            matches
              ? <Row style={{ marginTop: 15}} type="flex" justify="center">
                <Col xs={{ span: 2, offset: 0 }}>
                  {!this.props.core.previous? <Button disabled>Anterior</Button> : <Button href={`/core/${this.props.core.previous}`}>Anterior</Button>}
                </Col>
                <Col xs={{ span: 2, offset: 20 }}>
                  {!this.props.core.next? <Button disabled>Siguiente</Button> : <Button href={`/core/${this.props.core.next}`}>Siguiente</Button>}
                </Col>
              </Row>
              : <MediaQuery minDeviceWidth={425}>
                {(matches) =>
                matches
                ?
                <Row style={{ marginTop: 15}} type="flex" justify="center">
                  <Col xs={{ span: 4, offset: 0 }}>
                    {!this.props.core.previous? <Button disabled>Anterior</Button> : <Button href={`/core/${this.props.core.previous}`}>Anterior</Button>}
                  </Col>
                  <Col xs={{ span: 4, offset: 16 }}>
                    {!this.props.core.next? <Button disabled>Siguiente</Button> : <Button href={`/core/${this.props.core.next}`}>Siguiente</Button>}
                  </Col>
                </Row>
                :
                <MediaQuery minDeviceWidth={375}>
                  {(matches) =>
                  matches
                  ? <Row style={{ marginTop: 15}} type="flex" justify="center">
                    <Col xs={{ span: 8, offset: 0 }}>
                      {!this.props.core.previous? <Button disabled>Ant.</Button> : <Button href={`/core/${this.props.core.previous}`}>Ant.</Button>}
                    </Col>
                    <Col xs={{ span: 4, offset: 12 }}>
                      {!this.props.core.next? <Button disabled>Sig.</Button> : <Button href={`/core/${this.props.core.next}`}>Sig.</Button>}
                    </Col>
                  </Row>
                  : <Row style={{ marginTop: 15}} type="flex" justify="center">
                    <Col xs={{ span: 6, offset: 0 }}>
                      {!this.props.core.previous? <Button disabled>Ant.</Button> : <Button href={`/core/${this.props.core.previous}`}>Ant.</Button>}
                    </Col>
                    <Col xs={{ span: 5, offset: 13 }}>
                      {!this.props.core.next? <Button disabled>Sig.</Button> : <Button href={`/core/${this.props.core.next}`}>Sig.</Button>}
                    </Col>
                  </Row>
                  }
                </MediaQuery>

                }
                </MediaQuery>
            }
          </MediaQuery>


          <Card
            style={{ marginTop: 15 }}
            hoverable={true}
            bordered={true}
          >
          <h4>Descripción del curso:</h4>
          <Paragraph ellipsis={{ rows: 2, expandable: true }}>
            {this.props.core.description}
          </Paragraph>

          </Card>

          <Card
            style={{ marginTop: 16 }}
            hoverable={true}
            bordered={true}
            title="Texto del artículo"
          >
          <ReactMarkdown
            source={!this.props.core.text_article ? null  : this.props.core.text_article}

            escapeHtml={false}
          />
          </Card>

          <Card
            style={{ marginTop: 16 }}
            hoverable={true}
            bordered={true}
            title="Podcast - Audio"
          >
            <ReactAudioPlayer
              src={this.props.core.audio}
              style={{ width: "100%" }}
              autoPlay={false}
              controls
            />
          </Card>

          <MediaQuery minDeviceWidth={768}>
            {(matches) =>
            matches
              ? <Card
                style={{ marginTop: 16 }}
                hoverable={true}
                bordered={true}
                title="Infografia"
              >
                <img
                  className="infographics-card"
                  alt = "" //"Doofus no infographics here!"
                  src={this.props.core.infographics}
                />
              </Card>
              : <Card
                  style={{ marginTop: 16 }}
                  hoverable={true}
                  bordered={true}
                  title="Infografia"
                >
                  <img
                    width = "100%"
                    height = "auto"
                    alt = "" //"Doofus no infographics here!"
                    src={this.props.core.infographics}
                  />
                </Card>
            }
          </MediaQuery>


        </div>

        <br />

        <Row>
          <Title level={4}> ¿Interesado en leer más? Explora cursos prácticos relacionados </Title>
          <Text strong> La siguiente lista de cursos prácticos son basados del curso teórico anterior.</Text>
          <List
          style={{padding:20}}
          itemLayout="vertical"
          size="default"
          pagination={{
            onChange: page => {
              console.log(page);
            },
            pageSize:10,
          }}
          dataSource={this.props.example}
          renderItem={item => (
            <List.Item
              key={item.title}
              extra={
                <img
                  width={272}
                  height={145}
                  alt = "cover" //"Doofus no thumbnail here!"
                  src={item.thumbnail}
                />
              }
            >
              <List.Item.Meta
                style={{padding: 20}}
                avatar={
                  <Avatar 
                  src={typeof item.author_avatar[0] === 'undefined' ?
                            src="https://s3.amazonaws.com/panzofi.com/Avatar.png"
                          : src=`${item.author_avatar[0]["profile_picture"]}`}
                  />
                }
                title={<a href={`/example/${item.slug}`}>{item.title}</a>}
                description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
              />

            </List.Item>
          )}
        />
        </Row>
        <br />

        <Title level={4}> ¿No has encontrado lo que estás buscando? ¿Por qué no subes un curso práctico? </Title>
        <Row type="flex" align="middle">
        <Col span={8}>
          <Icon type="form" onClick={this.showModal} style={{ padding:10, fontSize: 60, color: "#2397F0"}} />
        </Col>
        <Col span={16}>
          <Text strong> Haz clic en el icono del formulario para subir un curso práctico para el curso teórico anterior. </Text>
        </Col>
        <Modal
          title="Carga rápida"
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
        >
        {
            this.state.sending?
            <div className="loader">
              <Spin tip="Gran ejemplo! Espera mientras validamos tu contenido en nuestro servidor. Por favor, no presiones actualizar o regresar." indicator={spinIcon} />
            </div>
            :
            <div>
              <div style={{textAlign:"left"}}>

                <div>
                <Text strong>¿Cuál preferirías que sea el título del curso? </Text><br />
                <Input
                  style={{ marginTop: 20 }}
                  onChange={e => {
                    e.persist();
                    this.setState({title: e.target.value});
                  }}
                  placeholder="dale un título interesante a tu curso. Límite de palabras: 40 "/>
                </div>

                <div style={{ marginTop: 40, marginBottom: 35}}>
                <Text strong>Describe brevemente los contenidos del curso. </Text> <br />
                <TextArea
                  style={{ marginTop: 20 }}
                  onChange={e => {
                    e.persist();
                    this.setState({description: e.target.value});
                  }}
                  placeholder="escribe una descripción sobre tu curso" rows={4} />
                </div>

              </div>
              <div style={{textAlign:"left"}}>

                <div>
                <Text strong> Subir la imagen del curso </Text><br /><br />
                <Upload
                  name="avatar"
                  listType="picture-card"
                  showUploadList={false}
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  beforeUpload={beforeUpload}
                  onChange={this.thumbnailUpload}
                >
                  {imgUrl ? <img src={imgUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                </Upload>
                <Text secondary> <b>*</b>El tipo admitido incluye <i> jpeg </i> y <i> png </i>. Límite máximo de carga <i> 2MB</i></Text><br />
                </div>


              </div><br />
              <div style={{textAlign:"left"}}>

                <Text strong> Arrastra el archivo en el cuadro o haz clic a continuación para cargar un video para el curso </Text><br /><br />
                <Dragger onChange={this.videoUpload}>
                  <p className="ant-upload-drag-icon">
                    <Icon type="inbox" />
                  </p>
                  <p className="ant-upload-text">Haz clic o arrastra el archivo a esta área para cargar</p><br />
                  <p className="ant-upload-hint">
                    Comprima el tamaño del archivo de video antes de subirlo. <br />
                    Tenga en cuenta que las extensiones válidas incluyen <b> .mp4, .ogv </b> y <b> .webm </b>. Límite de carga <b> 30 MB </b>
                  </p>
                </Dragger>
              </div>
            </div>
        }

        </Modal>
        </Row>

      </div>
    );
  };

}

const mapStateToProps = state => {
  return {
    token: state.token
  }
}

export default connect(mapStateToProps)(CoreDetail);
