import React from 'react';

import MediaQuery from 'react-responsive';

import { Button, DatePicker, Divider, Drawer, Form, Input, Select, Slider } from 'antd';

const { Option } = Select;
const { RangePicker } = DatePicker;

class Filter extends React.Component {

  constructor(props){
    super(props);
    this.state =  {
      visible: false,
    };
  }

  showDrawer = () => {
    this.setState({
      visible: true
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  onDateChange = (date, dateString) => {
    this.props.publishAfterFilterCallback(dateString[0]);
    this.props.publishBeforeFilterCallback(dateString[1]);
  }

  render(){

    return(
      <div>
      <MediaQuery minDeviceWidth={768}>
        {(matches) =>
        matches
          ? <Button type="default" size="small" onClick={this.showDrawer}>
            Filtrar Resultados
          </Button>
          : <Button type="default" size="small" onClick={this.showDrawer} block>
            Filtrar Resultados
          </Button>
        }
        </MediaQuery>


       <Drawer
         title="Filtrar resultados de búsqueda ..."
         placement="right"
         closable={true}
         onClose={this.onClose}
         visible={this.state.visible}
        >
         <Form layout="vertical">

           <h4>Filtro basado en calificación</h4><br/>

           <Form.Item label="Calificación mínima de diversión">
              <Slider onChange={this.props.funFilterCallback} defaultValue={0} max={100}/>
           </Form.Item>

           <Form.Item label="Calificación mínima de útil">
              <Slider onChange={this.props.usefulFilterCallback} defaultValue={0} max={100}/>
           </Form.Item>

           <Form.Item label="Calificación mínima de eficiente">
              <Slider onChange={this.props.efficientFilterCallback} defaultValue={0} max={100}/>
           </Form.Item>

           <Divider />

           <h4>Filtro basado en el autor</h4> <br/>

           <Form.Item label="Nombre del autor...">
              <Input onChange={(event) => this.props.authorFilterCallback(event.target.value)} size="small" name="author" placeholder="filtrar por nombre de autor ..." />
           </Form.Item>

          <Divider />

          <h4>Filtro basado en fecha</h4><br/>

          <Form.Item label="Búsqueda por antigüedad del contenido">
            <Select
               size="small"
               showSearch
               style={{ width: 200 }}
               placeholder="selecciona antigüedad de archivos"
               optionFilterProp="children"
               onChange={(value,option) => this.props.publishFilterCallback(value, option)}
               filterOption={(input, option) =>
                 option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
               }
            >
               <Option value="">Selecciona un filtro</Option>
               <Option value="today">Hoy</Option>
               <Option value="yesterday">Ayer</Option>
               <Option value="week">Últimos 7 días</Option>
               <Option value="month">Este mes</Option>
               <Option value="year">Este año</Option>
            </Select>
          </Form.Item>


          <Form.Item label="Ingrese un rango de fechas para buscar en los archivos">
            <RangePicker size="small" onChange={this.onDateChange} />
          </Form.Item>


         </Form>
       </Drawer>
     </div>
    );
  }

}

export default Filter;
