import React from 'react';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';

import MediaQuery from 'react-responsive';

import { Button, Result, Typography } from 'antd';

import Profile from '../../components/User/Profile';
import ProfileResponsive from '../../components/User/ProfileResponsive';
import './User.css';

const { Title } = Typography;

const isEmpty = (obj) => {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
         return false;
   }
   return true;
};

class ProfileView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      found: true,
      slug: '',
      profile: {}
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.location.pathname !== this.props.location.pathname){
      this.componentDidMount()
    }
  }

  componentDidMount() {
    this.loadProfile()
  };

  loadProfile() {
    if(!this.props.location.keyword || !this.props.location.keyword !== '') {
      const slug = this.props.location.pathname.split('/profile/')[1];
      axios.get(`${apiHost}/api/v1/profile/${slug}/`)
      .then(res => {
        this.setState({
            found: true,
            slug: slug,
            profile: res.data
           });
      })
      .catch(err => {
        this.setState({
          found: false
        });
      });
    }
    else {
      console.log("Profile default");
    }
  }

  render() {

    if (!this.state.found) {
      return(
        <Result
          status="404"
          title="404"
          subTitle="Lo sentimos, la configuración de este perfil que visitó no existe."
          extra={<Button type="primary" href='/'>Regresar al inicio</Button>}
        />
      );
    };
    if (!isEmpty(this.state.profile)) {
      return(
        <div>
        <MediaQuery minDeviceWidth={1024}>
          {(matches) =>
          matches
            ? <Profile {...this.state} />
            : <ProfileResponsive {...this.state} />
          }
        </MediaQuery>

        </div>
      );

    } else {
      return(<Title level={4}>cargando... por favor espera</Title>);
    }

  };

}

export default ProfileView;
