import React from 'react';

import cookie from "react-cookies";
import { connect } from 'react-redux';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';
import { loadXHR } from '../../file-processor-api';

import MediaQuery from 'react-responsive';

import Profile from '../../components/User/Settings/Profile';
import Avatar from '../../components/User/Settings/Avatar';

import { Button, Collapse, Form, Icon, notification, Result, Tabs, Typography } from 'antd';

const { Panel } = Collapse;
const { TabPane } = Tabs;
const { Title } = Typography;

const isEmpty = (obj) => {
  for(var key in obj) {
      if(obj.hasOwnProperty(key))
         return false;
   }
   return true;
};

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

class SettingsView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      authorized: true,
      found: true,
      sending: false,

      profile: {},

      profile_introduction: '',
      location: '',
      website: '',
      phone: '',
      profile_picture: ''

    };
  }

  componentDidMount() {
      if(!this.props.location.keyword || this.props.location.keyword !== ''){

        const slug = this.props.location.pathname.split('/settings/')[1];
        axios.get(`${apiHost}/api/v1/profile/${slug}/`)
        .then(res => {
          if(res.data.user === localStorage.getItem('username')) {
            this.setState({
                authorized: true,
                found: true,
                profile: res.data
            });

            this.setState({
              profile_introduction: this.state.profile.profile_introduction,
              location: this.state.profile.location,
              website: this.state.profile.website
            })

            !this.state.profile.phone ? this.setState({ phone: ''})
                                    : this.setState({phone: this.state.profile.phone});

            !this.state.profile.profile_picture ? this.setState({profile_picture: ''})
                                  : loadXHR(this.state.profile.profile_picture)
                                  .then((blob) => {
                                    return new Promise((resolve, reject) => {
                                      const reader = new FileReader();
                                      reader.readAsDataURL(blob);
                                      reader.onload = () => resolve(reader.result);
                                      reader.onerror = error => reject(error);

                                    })
                                    .then(data => {
                                      this.setState({
                                        profile_picture: data
                                      });
                                    });
                                  });

          } else {
            this.setState({
              authorized: false
            });
          }
        })
        .catch(err => {
          this.setState({
             found: false
           });
        });
      }

  }

  updateProfile = (data) => {

    this.setState({
      sending: !this.state.sending
    });

    const openNotification = (type, msg, des) => {
      notification[type]({
        message: msg,
        description: des
      });
    };

    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.withCredentials = true
    axios.defaults.headers = {
       "Authorization": 'Token '+ this.props.token,
       "X-CSRFTOKEN": cookie.load("csrftoken")
    };

    const fd = new FormData();

    data.profile_introduction === "default" || data.profile_introduction === undefined || data.profile_introduction === ''? fd.append("profile_introduction", this.state.profile_introduction) : fd.append("profile_introduction", data.profile_introduction);
    data.location === "default" || data.location === undefined || data.location === ''? fd.append("location", this.state.location) : fd.append("location", data.location);
    data.website === "default" || data.website === undefined || data.website === ''? fd.append("website", this.state.website) : fd.append("website", data.website);
    data.phone === "default" || data.phone === undefined || data.phone === null || data.phone === ''? fd.append("phone", this.state.phone) : fd.append("phone", data.phone);
    data.profile_picture === "default" || data.profile_picture === undefined ? fd.append("profile_picture", this.state.profile_picture) : fd.append("profile_picture", data.profile_picture);

    return axios.put(`${apiHost}/api/v1/profile/${this.state.profile.slug}/edit/`, fd)
     .then(res => {
        if(res.status === 200) {
          openNotification('success','Hecho ',' La configuración del perfil se actualizó correctamente.')
        };
        this.setState({
          sending: !this.state.sending
        });
        this.componentDidMount();
      })
     .catch(err => {
       openNotification('error','Error de red ',' El perfil no se actualizó.')
       this.setState({
         sending: !this.state.sending
       });
       this.componentDidMount();
     });


  };

  render() {

    if (!this.state.found) {
      return(
        <Result
          status="404"
          title="404"
          subTitle="Lo sentimos, la configuración de este perfil que visitó no existe."
          extra={<Button type="primary" href='/'>Regresar al inicio</Button>}
        />
      );
    };

    if (!this.state.authorized) {
      return(
        <Result
          status="403"
          title="Acceso no autorizado"
          subTitle="¡Parece una caja vacía! Se requiere iniciar sesión para acceder a la configuración"
          extra={[
            <Button href = '/login/' > Inicia sesión en Panzofi </Button>,
            <Button href = '/signup/'> Regístrate en Panzofi </Button>
          ]}
        />
      );
    };

    if (!isEmpty(this.state.profile)) {
      if(!localStorage.getItem('username')) {
        return(
          <Result
            status="403"
            title="Acceso no autorizado"
            subTitle="¡Parece una caja vacía! Se requiere iniciar sesión para acceder a la configuración"
            extra={[
              <Button href = '/login/' > Inicia sesión en Panzofi </Button>,
              <Button href = '/signup/'> Regístrate en Panzofi </Button>
            ]}
         />
        ); }
      else { return (
        <MediaQuery minDeviceWidth={1024}>
          {(matches) =>
          matches
            ? <div style={{padding: 80}}>

              <Tabs tabPosition="left">
                <TabPane tab="Información del perfil" key="1">
                  <Profile {...this.state} updateProfile={this.updateProfile}/>
                </TabPane>
                <TabPane tab="Actualizar Avatar" key="2">
                  <Avatar {...this.state} updateProfile={this.updateProfile}/>
                </TabPane>
              </Tabs>

            </div>
            : <Collapse
              accordion
              bordered={false}
              defaultActiveKey={['1']}
              expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
            >
              <Panel header="Información del perfil" key="1" style={customPanelStyle}>
                <Profile {...this.state} updateProfile={this.updateProfile}/>
              </Panel>
              <Panel header="Actualizar Avatar" key="2" style={customPanelStyle}>
                <Avatar {...this.state} updateProfile={this.updateProfile}/>
              </Panel>

            </Collapse>
          }
        </MediaQuery>

    );};

    } else {
     return(<Title level={4}>loading..please wait</Title>);
    }
  };

}


const WrappedSettingsLayout = Form.create({ name: 'settings' })(SettingsView);

const mapStateToProps = state => {
  return {
    token: state.token
  }
}

export default connect(mapStateToProps)(WrappedSettingsLayout);
