import React from 'react';

import cookie from "react-cookies";
import { connect } from 'react-redux';

import { notification } from 'antd';

import axios from 'axios';
import { apiHost } from '../../config.dev.js';
import { loadXHR } from '../../file-processor-api';

import MediaQuery from 'react-responsive';

import CoreUpdate from '../../components/Content/Core/Update';
import CoreUpdateResponsive from '../../components/Content/Core/UpdateResponsive';

class CoreContentUpdate extends React.Component {

  constructor(props){
   super(props);
   this.state = {
     core: [],

     init_content: {},
     update_content: {},

     categories: [],

     category: '',
     title: '',
     description: '',
     thumbnail: '',
     infographics: '',
     video: '',
     audio: '',
     textArticle:'',
     previous: '',
     next: '',

     sending: false
   }
  }

  componentDidMount() {
    axios.all([
      axios.get(`${apiHost}/api/v1/core/${this.props.match.params.slug}/`),
      axios.get(`${apiHost}/api/v1/categories/`),
      axios.get(`${apiHost}/api/v1/core/`)
    ])
    .then(axios.spread((init, categories, core) => {
      this.setState({
        init_content: init.data,
        categories: categories.data,
        core: core.data
      })

      this.setState({
        title: this.state.init_content.title,
        description: this.state.init_content.description,
        textArticle: this.state.init_content.text_article
      });

      !this.state.init_content.category ? this.setState({category: ''})
                            : this.state.categories.forEach((category) => {
                                if (category.category === this.state.init_content.category) {
                                  this.setState({
                                    category: category.id
                                  });
                                }
                            });

      !this.state.init_content.thumbnail ? this.setState({thumbnail: ''})
                            : loadXHR(this.state.init_content.thumbnail)
                            .then((blob) => {
                              return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);

                              })
                              .then(data => {
                                this.setState({
                                  thumbnail: data
                                });
                              });
                            });

      !this.state.init_content.infographics ? this.setState({infographics: ''})
                            : loadXHR(this.state.init_content.infographics)
                            .then((blob) => {
                              return new Promise((resolve, reject) => {
                                const reader = new FileReader();
                                reader.readAsDataURL(blob);
                                reader.onload = () => resolve(reader.result);
                                reader.onerror = error => reject(error);

                              })
                              .then(data => {
                                this.setState({
                                  infographics: data
                                });
                              });
                            });

      !this.state.init_content.video ? this.setState({video: ''})
                            : loadXHR(this.state.init_content.video)
                            .then((blob) => {
                              const file = new File([blob], this.state.init_content.video.split('/')[4].split('?')[0], {lastModified: new Date()});
                              this.setState({
                                video: file
                              });
                            });

      !this.state.init_content.audio ? this.setState({audio: ''})
                            : loadXHR(this.state.init_content.audio)
                            .then((blob) => {
                              const file = new File([blob], this.state.init_content.audio.split('/')[4].split('?')[0], {lastModified: new Date()});
                              this.setState({
                                audio: file
                              });
                            });


      !this.state.init_content.previous ? this.setState({previous: ''})
                            : this.state.core.forEach((core) => {
                              if(core.slug === this.state.init_content.previous) {
                                this.setState({
                                  previous: core.id
                                });
                              }
                            });

      !this.state.init_content.next ? this.setState({next: ''})
                            : this.state.core.forEach((core) => {
                              if(core.slug === this.state.init_content.next) {
                                this.setState({
                                  next: core.id
                                });
                              }
                            });

    }));
  }

  updateContent = (data) => {

    this.setState({
      sending: !this.state.sending
    });

    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
    axios.defaults.withCredentials = true
    axios.defaults.headers = {
      "Authorization": 'Token '+ this.props.token,
      "X-CSRFTOKEN": cookie.load("csrftoken")
    }

    const fd = new FormData();

    data.title === "default" || data.title === undefined? fd.append("title", this.state.title) : fd.append("title", data.title);
    data.description === "default" || data.description === undefined ? fd.append("description", this.state.description) : fd.append("description", data.description);
    data.category === "default" || data.category === undefined ? fd.append("category", this.state.category) : fd.append("category", data.category);
    data.thumbnail === "default" || data.thumbnail === undefined ? fd.append("thumbnail", this.state.thumbnail) : fd.append("thumbnail", data.thumbnail);
    data.infographics === "default" || data.infographics === undefined ? fd.append("infographics", this.state.infographics) : fd.append("infographics", data.infographics);
    data.video === "default" || data.video === undefined ? fd.append("video", this.state.video) : fd.append("video", data.video);
    data.audio === "default" || data.audio === undefined ? fd.append("audio", this.state.audio) : fd.append("audio", data.audio);
    data.textArticle === "default" || data.textArticle === undefined ? fd.append("text_article", this.state.textArticle) : fd.append("text_article", data.textArticle);
    data.previous === "default" || data.previous === undefined ? fd.append("previous", this.state.previous) : fd.append("previous", data.previous);
    data.next === "default" || data.next === undefined ? fd.append("next", this.state.next) : fd.append("next", data.next);

    return axios.put(`${apiHost}/api/v1/core/${this.props.match.params.slug}/edit/`,fd)
    .then(res => {
      if(res.status === 200) {
        notification['success']({
          message: 'Done',
          description: 'Your course has been successfully updated!'
        });
      }
      this.setState({
        sending: !this.state.sending
      });
      this.componentDidMount();
    })
    .catch(err => {
      notification['error']({
        message: 'Error',
        description: 'Course was not updated!'
      });
      this.setState({
        sending: !this.state.sending
      });
      this.componentDidMount();
    });
  }

  render() {
    return(
      <MediaQuery minDeviceWidth={1024}>
        {(matches) =>
        matches
          ? <CoreUpdate {...this.state} updateContent={this.updateContent}/>
          : <CoreUpdateResponsive {...this.state} updateContent={this.updateContent}/>
        }
      </MediaQuery>
    );
  }

}

const mapStateToProps = state => {
  return {
    token: state.token
  }
}

export default connect(mapStateToProps)(CoreContentUpdate);
