import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../store/actions/auth';

import LoginBubble from '../../components/Bubble/Login';
import UserBubble from '../../components/Bubble/User';

class Bubble extends React.Component {

  logout = () => {
    this.props.logout()
  };

  render(){

      const isLoggedIn = this.props.isLoggedIn;

      if (isLoggedIn) {
        return <UserBubble className="user-bubble" {...this.props} logout={this.logout}/>;
      }
      return <LoginBubble {...this.props}/>;
    }

}

const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(actions.logout())
  }
}

export default withRouter(connect(null, mapDispatchToProps)(Bubble));
