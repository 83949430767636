import React from 'react';

import { Icon, Tooltip } from 'antd';

class LoginBubble extends React.Component {

  login = () => {
    this.props.history.push('/login/')
  };

  render() {
    return (
      <div className="login-bubble">
        <Tooltip title="inicia sesión">
        <Icon type="login" onClick={this.login} />
        </ Tooltip>
      </div>
    );
  };

}

export default LoginBubble;
