import React from 'react';

import Essentials from './Section/Essentials';
import Infographics from './Section/Infographics';
import Video from './Section/Video';
import Podcast from './Section/Podcast';
import TextArticle from './Section/TextArticle';
import Chronology from './Section/Chronology';


import { Col, Collapse, Icon, Row } from 'antd';

const { Panel } = Collapse;

const customPanelStyle = {
  background: '#f7f7f7',
  borderRadius: 4,
  marginBottom: 24,
  border: 0,
  overflow: 'hidden',
};

class CoreUpdateResponsive extends React.Component {


  render() {
    return (
      <Collapse
        accordion
        bordered={false}
        defaultActiveKey={['1']}
        expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0} />}
      >
        <Panel header="Esenciales" key="1" style={customPanelStyle}>
          <Essentials {...this.props} />
        </Panel>
        <Panel header="Imágenes y gráficos" key="2" style={customPanelStyle}>
          <Infographics {...this.props} />
        </Panel>
        <Panel header="Vídeo del curso" key="3" style={customPanelStyle}>
          <Video {...this.props} />
        </Panel>
        <Panel header="Podcast" key="4" style={customPanelStyle}>
          <Podcast {...this.props} />
        </Panel>
        <Panel header="Texto del artículo" key="5" style={customPanelStyle}>
          <TextArticle {...this.props} />
        </Panel>
        <Panel header="Cronología" key="6" style={customPanelStyle}>
          <Chronology {...this.props} />
        </Panel>
      </Collapse>
    
    );
  };
}


export default CoreUpdateResponsive;
