import React from 'react';
import Filter from './Filter';

import MediaQuery from 'react-responsive';

import { Button, Col, Divider, Icon, List, Row, Typography } from 'antd';

const { Paragraph, Title } = Typography;

class SearchResults extends React.Component {

  render(){
    let src = "";
    return (
        <div>
        {
          this.props.query === undefined ?
          <p>No se definieron palabras clave</p>
          :
          <p>Mostrando los resultados para: <i>{this.props.query}</i></p>
        }

        <MediaQuery minDeviceWidth={1440}>
          {(matches) =>
          matches
            ? <Row >
                 <Col span={1}>
                   <Filter
                     {...this.props}
                   />
                 </Col>
                 <Col span={1} offset={1}>
                   <Button type="danger" size="small" onClick={this.props.reset}>
                     Restablecer filtro
                   </Button>
                 </Col>
            </Row>
            : <MediaQuery minDeviceWidth={1024}>
              {(matches) =>
              matches
                ? <Row >
                     <Col span={2}>
                       <Filter
                         {...this.props}
                       />
                     </Col>
                     <Col span={1} offset={1}>
                       <Button type="danger" size="small" onClick={this.props.reset}>
                         Restablecer filtro
                       </Button>
                     </Col>
                </Row>
                : <MediaQuery minDeviceWidth={768}>
                  {(matches) =>
                  matches
                    ? <Row >
                         <Col span={3}>
                           <Filter
                             {...this.props}
                           />
                         </Col>
                         <Col span={1} offset={1}>
                           <Button type="danger" size="small" onClick={this.props.reset}>
                             Restablecer filtro
                           </Button>
                         </Col>
                    </Row>
                    : <div>
                     <Row >
                         <Col>
                           <Filter
                             {...this.props}
                           />
                         </Col>
                      </Row>
                      <Row style={{marginTop: 10}}>
                         <Col>
                           <Button type="danger" size="small" onClick={this.props.reset} block>
                             Restablecer filtro
                           </Button>
                         </Col>
                    </Row>
                    </div>

                  }
                  </MediaQuery>
              }
              </MediaQuery>
          }
        </MediaQuery>


         <Divider />
         <Title level={4}> Contenido teórico encontrado en el catálogo</Title>
         <MediaQuery minDeviceWidth={1024}>
           {(matches) =>
           matches
             ? <List
               itemLayout="vertical"
               size="default"
               pagination={{
                 onChange: page => {
                   console.log(page);
                 },
                 pageSize: 3,
               }}
               dataSource={this.props.core}
               renderItem={item => (
                 <List.Item
                   key={item.title}
                   actions={[
                     <span><Icon
                       style={{ marginRight: 8, paddingLeft: 30 }}
                       type="edit" theme="twoTone" key="list-vertical-edit" /> Autor: {item.author}
                     </span>,

                     <span><Icon
                       style={{ marginRight: 8 }}
                        type="calendar" theme="twoTone" key="list-vertical-calendar" /> Publicado en: {item.publish}
                     </span>,

                     <span><Icon
                       style={{ marginRight: 8 }}
                       type="eye" theme="twoTone" key="list-vertical-eye" />Vistas totales: {item.total_views}
                     </span>,

                   ]}
                   extra={
                     <img
                       width={272}
                       height={145}
                       alt = "" //"Doofus no thumbnail here!"
                       src={item.thumbnail}
                     />
                   }
                 >
                   <List.Item.Meta

                     title={<a href={`/core/${item.slug}`}>{item.title}</a>}
                     description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
                   />

                 </List.Item>
               )}
             />
             : <List
               itemLayout="vertical"
               size="default"
               pagination={{
                 onChange: page => {
                   console.log(page);
                 },
                 pageSize: 3,
               }}
               dataSource={this.props.core}
               renderItem={item => (
                 <List.Item
                   key={item.title}
                   extra={
                     <img
                       width={272}
                       height={145}
                       alt = "" //"Doofus no thumbnail here!"
                       src={item.thumbnail}
                     />
                   }
                 >
                   <List.Item.Meta

                     title={<a href={`/core/${item.slug}`}>{item.title}</a>}
                     description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
                   />

                 </List.Item>
               )}
             />
           }
          </MediaQuery>


         <Title level={4}> Contenido práctico encontrado en el catálogo</Title>
         <MediaQuery minDeviceWidth={1024}>
           {(matches) =>
           matches
             ? <List
               itemLayout="vertical"
               size="default"
               pagination={{
                 onChange: page => {
                   console.log(page);
                 },
                 pageSize: 3,
               }}
               dataSource={this.props.example}
               renderItem={item => (
                 <List.Item
                   key={item.title}
                   actions={[
                     <span><Icon
                       style={{ marginRight: 8, paddingLeft: 30 }}
                       type="edit" theme="twoTone" key="list-vertical-edit" /> Autor: {item.author}
                     </span>,

                     <span><Icon
                       style={{ marginRight: 8 }}
                        type="calendar" theme="twoTone" key="list-vertical-calendar" /> Publicado en: {item.publish}
                     </span>,

                     <span><Icon
                       style={{ marginRight: 8 }}
                       type="eye" theme="twoTone" key="list-vertical-eye" />Vistas totales: {item.total_views}
                     </span>,

                   ]}
                   extra={
                     <img
                       width={272}
                       height={145}
                       alt = "" //"Doofus no thumbnail here!"
                       src={item.thumbnail}
                     />
                   }
                 >
                   <List.Item.Meta
                     title={<a href={`/example/${item.slug}`}>{item.title}</a>}
                     description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
                   />

                 </List.Item>
               )}
             />
             : <List
               itemLayout="vertical"
               size="default"
               pagination={{
                 onChange: page => {
                   console.log(page);
                 },
                 pageSize: 3,
               }}
               dataSource={this.props.example}
               renderItem={item => (
                 <List.Item
                   key={item.title}
                   extra={
                     <img
                       width={272}
                       height={145}
                       alt = "" //"Doofus no thumbnail here!"
                       src={item.thumbnail}
                     />
                   }
                 >
                   <List.Item.Meta
                     title={<a href={`/example/${item.slug}`}>{item.title}</a>}
                     description={<Paragraph ellipsis={{ rows: 2, expandable: true }}>{item.description}</Paragraph>}
                   />

                 </List.Item>
               )}
             />
           }
        </MediaQuery>
        </div>
      );
  }

}

export default SearchResults;
